import axios, { Axios } from "axios";
import { API_BASE_URL } from ".";

const axiosClient: Axios = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
});

// Skip axios headers by default
axiosClient.interceptors.response.use(
  res => res.data,
  err => Promise.reject(err.response.data)
);

export default axiosClient;
