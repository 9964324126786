import React from "react";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Field, FormikErrors } from "formik";
import { selectMenuProps } from "../utils/select-menu-props.util";

export default function CustomDatePicker({
  fieldName,
  value,
  touched,
  error,
  datePickerProps,
}: {
  fieldName: string;
  value: any;
  touched?: boolean;
  error?: FormikErrors<Date> | undefined;
  datePickerProps?: DatePickerProps<Date>;
}) {
  return (
    <Field name={fieldName} id={fieldName}>
      {({ field }: { field: any }) => (
        <DatePicker
          MenuProps={{
            ...selectMenuProps,
          }}
          {...field}
          value={value}
          onChange={(newValue: any) => field.onChange({ target: { name: fieldName, value: newValue } })}
          {...datePickerProps}
          slotProps={{
            textField: {
              fullWidth: true,
              error: touched && Boolean(error),
              helperText: touched && error,
            },
          }}
        />
      )}
    </Field>
  );
}
