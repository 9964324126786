import React, { FocusEvent, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SmartDatePicker from "./SmartDatePicker";
import useSmartDateAPI, { NO_PREFERRED_DOCTOR } from "../hooks/useSmartDateAPI";
import {
  calculateBusinessDaysFromToday,
  getAllDaysInAMonth,
  isDateWithinAWeek,
} from "../../../utils/date.util";
import { FormikErrors, FormikTouched } from "formik";
import { TAvailableHours, TAppointmentFormValues } from "../types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { selectMenuProps } from "../../../utils/select-menu-props.util";

type CustomChangeEvent = {
  target: {
    name: string;
    value: Date | null | string;
  };
};

type SmartAppointmentProps = {
  apptRequestToken: string;
  handleChange: (event: CustomChangeEvent) => void;
  setFieldValue: (fieldName: string, fieldValue: string | undefined) => void;
  values: TAppointmentFormValues;
  touched: FormikTouched<TAppointmentFormValues>;
  errors: FormikErrors<TAppointmentFormValues>;
  handleBlur: (e: FocusEvent<any, Element>) => void;
};

const SmartAppointment = ({
  apptRequestToken,
  handleChange,
  setFieldValue,
  values,
  touched,
  errors,
  handleBlur,
}: SmartAppointmentProps) => {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [allDatesDisabled, setAllDatesDisabled] = useState(false);
  const [availableTimes, setAvailableTimes] = useState<TAvailableHours[]>([]);

  const {
    getAvailableDates,
    getAvailableTimes,
    isFetchingDates,
    isDayAvailable,
  } = useSmartDateAPI(apptRequestToken);

  useEffect(() => {
    const fetch = async () => {
      setIsInitialLoading(true);
      setAvailableTimes([]);
      const fiveBusinessDaysFromToday = calculateBusinessDaysFromToday(5);
      await getAvailableDates(
        values.preferredDate?.toDate() || fiveBusinessDaysFromToday.toDate(),
        values.appointmentReasonId,
        values.preferredDoctor
      );
      const allDisabled = checkIfAllDatesInAMonthAreDisabled(
        values.preferredDate || fiveBusinessDaysFromToday
      );

      setAllDatesDisabled(allDisabled);
      setIsInitialLoading(false);
    };
    fetch().catch(console.error);
  }, [values.appointmentReasonId, values.preferredDoctor]);

  useEffect(() => {
    if (!values.preferredDate) {
      return setAvailableTimes([]);
    }
    const newAvailableTimes = getAvailableTimes(
      values.preferredDate?.toDate(),
      values.appointmentReasonId,
      values.preferredDoctor
    );
    setAvailableTimes(newAvailableTimes);
    if (
      !newAvailableTimes.find(
        (availableTime) => availableTime.time === values.preferredTime
      )
    ) {
      setFieldValue('preferredTime', "")
    }
  }, [
    values.preferredDate,
    values.appointmentReasonId,
    isFetchingDates,
    values.preferredDoctor,
  ]);

  const onMonthChange = async (selectedDate: Dayjs) => {
    await getAvailableDates(
      selectedDate.toDate(),
      values.appointmentReasonId,
      values.preferredDoctor
    );
    const allDisabled = checkIfAllDatesInAMonthAreDisabled(selectedDate);
    setAllDatesDisabled(allDisabled);
  };

  const checkIfAllDatesInAMonthAreDisabled = (date: Dayjs) => {
    const selectedMonthDates = getAllDaysInAMonth(date.toDate());
    return selectedMonthDates.every((date) => {
      return shouldDisableDate(dayjs(date));
    });
  };

  const shouldDisableDate = (date: Dayjs) => {
    const dayDate = dayjs(date).startOf("day").toDate();
    return (
      !isDayAvailable(
        dayDate,
        values.appointmentReasonId,
        values.preferredDoctor
      ) || isDateWithinAWeek(dayDate)
    );
  };

  return (
    <>
      <SmartDatePicker
        key={"preferredDate"}
        isInitialLoading={isInitialLoading}
        allDatesDisabled={allDatesDisabled}
        isLoading={isFetchingDates}
        handleChange={(newValue: any) =>
          handleChange({
            target: { name: "preferredDate", value: newValue },
          })
        }
        value={values.preferredDate}
        shouldDisableDate={shouldDisableDate}
        onMonthChange={onMonthChange}
        inputLabel={values.isDirectBookingEnabled ? "Pick date" : "Preferred date"}
        error={errors.preferredDate}
        touched={touched.preferredDate}
      />

      <Grid item xs={6} mb={1}>
        <InputLabel shrink>{values.isDirectBookingEnabled ? "Pick" : "Preferred"} time</InputLabel>
        <Select
          disabled={!values.preferredDate}
          IconComponent={AccessTimeIcon}
          fullWidth={true}
          name={"preferredTime"}
          value={values.preferredTime || ""}
          onChange={(value: any) => {
            handleChange({
              target: {
                name: "preferredTime",
                value: value.target.value || "",
              },
            });
            const availableTime = availableTimes.find(
              (time) => value.target.value === time.time
            );
            const docLength = availableTime?.availableDoctors?.length;

            if (docLength && docLength > 0 && values.preferredDoctor !== NO_PREFERRED_DOCTOR) {
              setFieldValue(
                "practiceDoctorId",
                availableTime?.availableDoctors[0].id
              );
            }
            if(values.preferredDoctor === NO_PREFERRED_DOCTOR){
              setFieldValue(
                "practiceDoctorId",
                undefined
              );
            }
          }}
          onBlur={handleBlur}
          error={touched.preferredTime && Boolean(errors.preferredTime)}
          MenuProps={{
            ...selectMenuProps,
            style: {
              maxHeight: 205,
            },
          }}
        >
          {availableTimes.map((option: any, index: number) => (
            <MenuItem key={index} value={option.time}>
              {option.time}
            </MenuItem>
          ))}
        </Select>
        {!values.preferredDate && (
          <FormHelperText>Select a Preferred date</FormHelperText>
        )}
        {touched.preferredTime && errors.preferredTime && (
          <FormHelperText error>{errors.preferredTime}</FormHelperText>
        )}
      </Grid>

      {!values.isDirectBookingEnabled && (
        <SmartDatePicker
          key={"firstAlternativeDate"}
          isInitialLoading={isInitialLoading}
          allDatesDisabled={allDatesDisabled}
          isLoading={isFetchingDates}
          handleChange={(newValue: any) => {
            handleChange({
              target: { name: "firstAlternativeDate", value: newValue },
            });
          }}
          value={values.firstAlternativeDate}
          shouldDisableDate={shouldDisableDate}
          onMonthChange={onMonthChange}
          inputLabel={"Alternative date #1"}
          error={errors.firstAlternativeDate}
          touched={touched.firstAlternativeDate}
        />
      )}

      {!values.isDirectBookingEnabled && (
        <SmartDatePicker
          key={"secondAlternativeDate"}
          isInitialLoading={isInitialLoading}
          allDatesDisabled={allDatesDisabled}
          isLoading={isFetchingDates}
          handleChange={(newValue: any) =>
            handleChange({
              target: { name: "secondAlternativeDate", value: newValue },
            })
          }
          value={values.secondAlternativeDate}
          shouldDisableDate={shouldDisableDate}
          onMonthChange={onMonthChange}
          inputLabel={"Alternative date #2"}
          error={errors.secondAlternativeDate}
          touched={touched.secondAlternativeDate}
        />
      )}
    </>
  );
};

export default SmartAppointment;
