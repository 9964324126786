import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { IDynamicFormProps } from "./types";

const DynamicRadioGroup: React.FC<IDynamicFormProps> = ({
  fieldId,
  fieldLabel,
  fieldValue,
  fieldMandatory = false,
  fieldHelperText,
  fieldOptions,
  fieldDisabled,
  handleChange,
}) => (
  <FormControl disabled={fieldDisabled} required={fieldMandatory}>
    <FormLabel>{fieldLabel}</FormLabel>
    <RadioGroup
      id={fieldId}
      defaultValue={fieldValue}
      onChange={(event) => handleChange(fieldId, event)}
    >
      {fieldOptions &&
        fieldOptions.length > 0 &&
        fieldOptions.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio required={fieldMandatory} />}
            label={option}
            sx={{
              "& .MuiFormControlLabel-asterisk": {
                visibility: "hidden",
              },
            }}
          />
        ))}
    </RadioGroup>
    <FormHelperText>{fieldHelperText}</FormHelperText>
  </FormControl>
);

export default DynamicRadioGroup;
