import React from "react";
import { Button } from "@mui/material";

interface ButtonProps {
  children?: string | JSX.Element;
  onClick?: () => void;
  fontSize?: number;
  textDecoration?: string;
  color?: string;
  fontWeight?: number;
}

export function CloseTextButton(props: ButtonProps) {
  return (
    <Button
      variant="text"
      onClick={props.onClick}
      sx={{
        color: props.color || "inherit",
        fontWeight: props.fontWeight ? props.fontWeight : 400,
        textDecoration: props.textDecoration ? props.textDecoration : "underline",
        textTransform: "unset",
        fontSize: props.fontSize || "14px",
      }}
    >
      {props.children || "Close"}
    </Button>
  );
}
