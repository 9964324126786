import { useState } from "react";
import { unsubscribeReminders } from "../api/reminders";

interface IUseUnsubscribe {
  isLoading: boolean;
  isSuccessful: boolean | null;
  error: boolean | null;
  unsubscribeRequest: (reminderToken: string | undefined) => Promise<void>;
}

const useUnsubscribe = (): IUseUnsubscribe => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean | null>(null);
  const [error, setError] = useState<boolean | null>(null);

  const unsubscribeRequest = async (reminderToken: string | undefined) => {
    if (!reminderToken) {
      setError(true);
      return;
    }

    setLoading(true);
    try {
      await unsubscribeReminders(reminderToken);
      setIsSuccessful(true);
    } catch (err: unknown) {
      setError(true);
    }
    setLoading(false);
  };

  return { isSuccessful, isLoading, error, unsubscribeRequest };
};

export default useUnsubscribe;
