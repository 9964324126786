import React, { useState } from "react";
import { Stepper, Step, StepButton, Divider } from "@mui/material";

interface FormStepperProps {
  steps: string[];
  showStepper: boolean;
  children: (props: StepperPropsChildren) => React.ReactNode;
}

export interface StepperPropsChildren {
  activeStep: number;
  isActiveStepCompleted: boolean;
  handleCompleteStep: () => void;
  allStepsCompleted: () => boolean;
}

const StepperDivider: React.FC = () => (
  <Divider
    flexItem
    orientation="vertical"
    sx={{
      borderColor: "rgba(15,23,105,0.20)",
      height: "40px",
      marginX: 3,
    }}
  />
);

const FormStepper: React.FC<FormStepperProps> = ({
  steps,
  showStepper,
  children,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{ [key: number]: boolean }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleCompleteStep = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const isActiveStepCompleted = completed[activeStep] === true;

  return (
    <>
      <Stepper
        nonLinear
        activeStep={activeStep}
        connector={<StepperDivider />}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {!allStepsCompleted() &&
          showStepper &&
          steps?.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
      </Stepper>
      <React.Fragment>
        {children({
          activeStep,
          isActiveStepCompleted,
          handleCompleteStep,
          allStepsCompleted,
        })}
      </React.Fragment>
    </>
  );
};

export default FormStepper;
