import React, { ChangeEvent } from "react";
import { InputLabel, TextField } from "@mui/material";
import { IDynamicFormProps } from "./types";

const DynamicInput: React.FC<IDynamicFormProps> = ({
  fieldId,
  fieldLabel,
  fieldPlaceholder = "",
  fieldValue = "",
  fieldHelperText = "",
  fieldMandatory,
  fieldDisabled,
  handleChange,
}) => (
  <>
    <InputLabel shrink sx={{ whiteSpace: "pre-wrap" }}>
      {`${fieldLabel}${fieldMandatory ? " *" : ""}`}
    </InputLabel>
    <TextField
      type="text"
      id={fieldId}
      disabled={fieldDisabled}
      placeholder={fieldPlaceholder || ""}
      value={fieldValue}
      helperText={fieldHelperText}
      onChange={(event) =>
        handleChange(fieldId, event as ChangeEvent<HTMLInputElement>)
      }
      required={fieldMandatory}
      fullWidth
    />
  </>
);

export default DynamicInput;
