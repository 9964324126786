import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { selectMenuProps } from "../../../utils/select-menu-props.util";
import PreferredDoctor from "../components/PreferredDoctor";
import SmartAppointment from "../components/SmartAppointment";
import { fillPetsArray } from "../helper";
import { FormikErrors, FormikProps } from "formik";
import {
  TAnimal,
  TAppointmentFormValues,
  TAppointmentReason,
  TClient,
  TDayHourOfOperation,
  TPracticeData,
} from "../types";
import DatePicker from "../components/DatePicker";
import HourPicker from "../components/HourPicker";

type TAppointmentStepProps = {
  apptRequestToken: string;
  intPetsCount: number;
  appointmentReason: TAppointmentReason | null;
  CancelBtn: React.ReactNode;
  handleNext: () => void;
  isValidStep: (arg: number) => Promise<boolean>;
  clientLookup: TClient | null;
  practiceData: TPracticeData;
} & Pick<
  FormikProps<TAppointmentFormValues>,
  | "values"
  | "touched"
  | "errors"
  | "status"
  | "handleChange"
  | "handleBlur"
  | "setFieldValue"
>;

export type TBusinessHours = Map<string, TDayHourOfOperation>;

const AppointmentStep: React.FC<TAppointmentStepProps> = ({
  apptRequestToken,
  intPetsCount,
  CancelBtn,
  handleNext,
  isValidStep,
  clientLookup,
  appointmentReason,
  practiceData,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
}) => {
  const [allowAnimalFreeTyping, setAllowAnimalFreeTyping] = useState<boolean>(true);

  useEffect(() => {
    setAllowAnimalFreeTyping(values.isDirectBookingEnabled ? false : true);
  }, [values.isDirectBookingEnabled]);

  const ADD_NEW_PET_OPTION = "ADD_PET_OPTION";

  const shouldShowSpecies = () => {
    if (!values.isDirectBookingEnabled) return false;

    // For direct booking, only 1 pet can be added hence checking for first element directly
    const animalAlreadyExists = clientLookup?.animals?.some(
      (clientAnimal: TAnimal) => values?.pets[0]?.name === clientAnimal?.name
    );

    if (practiceData.species?.length < 1) return false;

    if (
      values.isDirectBookingEnabled &&
      allowAnimalFreeTyping &&
      clientLookup?.animals?.length &&
      clientLookup?.animals?.length > 0 &&
      !animalAlreadyExists &&
      practiceData.species?.length > 0
    ) {
      return true;
    }

    if (values.pets.length < 1 || !values.pets[0].name) return false;

    return !animalAlreadyExists;
  };

  return (
    <>
      <Typography fontSize={24} mt={2}>
        Add information for pet companion
      </Typography>
      <Grid container spacing={"18px"} mt={2}>
        <PreferredDoctor
          practiceData={practiceData}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          selectedAppointmentReason={appointmentReason}
          values={values}
        />

        {!values.isDirectBookingEnabled && (
          <Grid item xs={12} sm={6} mb={1}>
            <InputLabel shrink># of pets being scheduled</InputLabel>
            <Select
              MenuProps={{
                ...selectMenuProps,
              }}
              fullWidth
              name={"petsCount"}
              value={values.petsCount}
              onChange={(e) => {
                handleChange(e);
                const count = +String(e.target.value).replace("+", "");
                setFieldValue("pets", fillPetsArray(count, values.pets));
              }}
              onBlur={handleBlur}
              displayEmpty
              error={touched.petsCount && Boolean(errors.petsCount)}
            >
              {["1", "2", "3", "4", "4+"].map((num: string, i: number) => (
                <MenuItem key={i} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
            {touched.petsCount && errors.petsCount && (
              <FormHelperText error>{errors.petsCount}</FormHelperText>
            )}
          </Grid>
        )}

        {Array(intPetsCount)
          .fill(0)
          .map((_, idx) => {
            const petName = values.pets?.at(idx)?.name;
            const nameTouched = touched.pets?.at(idx)?.name;

            const nameError = (
              errors.pets?.at(idx) as FormikErrors<{
                name: string;
              }>
            )?.name;

            const petNameLength = values.pets?.at(idx)?.name?.length;
            const hasContent = petNameLength && petNameLength > 0;

            const filteredChosenAnimals: TAnimal[] =
              clientLookup?.animals?.filter(
                (clientAnimal: TAnimal) =>
                  !values?.pets?.some(
                    (alreadyChosenAnimal) =>
                      alreadyChosenAnimal?.name === clientAnimal?.name
                  )
              ) || [];

            return (
              <Fragment key={idx}>
                <Grid
                  item
                  xs={12}
                  sm={values.isDirectBookingEnabled ? 12 : 6}
                  mb={1}
                >
                  <InputLabel shrink>
                    Pet name {intPetsCount > 1 ? idx + 1 : ""}
                  </InputLabel>
                  {clientLookup &&
                  clientLookup.isActive &&
                  clientLookup?.animals?.length > 0 ? (
                    <>
                      {allowAnimalFreeTyping ? (
                        <Autocomplete
                          freeSolo
                          id="animals-dropdown"
                          disableClearable
                          getOptionLabel={(animal) => (animal as TAnimal).name}
                          options={filteredChosenAnimals}
                          onBlur={handleBlur}
                          onChange={(event, newValue) => {
                            setFieldValue(
                              `pets.${idx}.name`,
                              (newValue as TAnimal).name
                            );
                            handleBlur(event);
                          }}
                          onInputChange={(event, newInputValue) => {
                            setFieldValue(`pets.${idx}.name`, newInputValue);
                            handleBlur(event);
                          }}
                          sx={{
                            "& .MuiInputBase-root": {
                              padding: "4px",
                            },
                          }}
                          blurOnSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              name={`pets.${idx}.name`}
                              value={petName || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={nameTouched ? nameError : ""}
                              error={nameTouched && Boolean(nameError)}
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              sx={{ whiteSpace: 'pre-line' }}
                            />
                          )}
                        />
                      ) : (
                        <>
                          <Select
                            MenuProps={{
                              ...selectMenuProps,
                            }}
                            fullWidth
                            name={`pets.${idx}.name`}
                            value={petName}
                            onChange={(e) => {
                              if (e.target.value === ADD_NEW_PET_OPTION) {
                                setAllowAnimalFreeTyping(true);
                                setFieldValue(`pets.${idx}.name`, "");
                              } else {
                                setFieldValue(
                                  `pets.${idx}.name`,
                                  e.target.value
                                );
                              }
                            }}
                            onBlur={handleBlur}
                          >
                            <MenuItem
                              key={ADD_NEW_PET_OPTION + idx}
                              value={ADD_NEW_PET_OPTION}
                              sx={{
                                background: "#edefff",
                                borderRadius: "10px",
                                marginX: 1,
                                paddingY: 1,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{ fontWeight: 600, color: "#5a71ff" }}
                                >
                                  Add new pet
                                </Typography>
                              </Box>
                            </MenuItem>
                            {clientLookup?.animals?.map((animal) => (
                              <MenuItem
                                key={animal.name}
                                value={animal.name}
                                sx={{
                                  marginY: 1,
                                }}
                              >
                                {animal.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    </>
                  ) : (
                    <TextField
                      fullWidth
                      name={`pets.${idx}.name`}
                      value={petName || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={nameTouched ? nameError : ""}
                      error={nameTouched && Boolean(nameError)}
                      sx={{ whiteSpace: "pre-line" }}
                    />
                  )}
                </Grid>
              </Fragment>
            );
          })}

        {shouldShowSpecies() && (
          <Grid item xs={12} sm={12} mb={1}>
            <InputLabel shrink>Species</InputLabel>
            <Select
              MenuProps={{
                ...selectMenuProps,
              }}
              fullWidth
              name={"species"}
              value={values.species}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.species && Boolean(errors.species)}
            >
              {practiceData.species?.map((val) => (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
            {touched.species && Boolean(errors.species) && (
              <FormHelperText error>{errors.species}</FormHelperText>
            )}
          </Grid>
        )}

        {!values.isDirectBookingEnabled && practiceData.footerCustomCopy && (
          <Grid item xs={12} mb={1}>
            <Alert
              icon={false}
              severity="info"
              sx={{
                wordBreak: "break-word",
              }}
              className="theme-gray"
            >
              {practiceData.footerCustomCopy}
            </Alert>
          </Grid>
        )}

        {/* Line break */}
        {!values.isDirectBookingEnabled && <Grid item xs={12} />}

        {practiceData.isSmartAppointmentRequestsEnabled ? (
          <SmartAppointment
            apptRequestToken={apptRequestToken}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            handleBlur={handleBlur}
          />
        ) : (
          <>
            <Grid item xs={12} sm={6} mb={1}>
              <DatePicker
                label={`${
                  values.isDirectBookingEnabled ? "Pick" : "Preferred"
                } date`}
                fieldName="preferredDate"
                value={values.preferredDate}
                practiceData={practiceData}
                touched={Boolean(touched.preferredDate)}
                error={errors.preferredDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={1}>
              <HourPicker
                practiceData={practiceData}
                values={values}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              {touched.preferredTime && errors.preferredTime && (
                <FormHelperText error>{errors.preferredTime}</FormHelperText>
              )}
            </Grid>

            {!values.isDirectBookingEnabled && (
              <Grid item xs={12} sm={6} mb={1}>
                <DatePicker
                  label={"Alternative date #1"}
                  fieldName="firstAlternativeDate"
                  value={values.firstAlternativeDate}
                  practiceData={practiceData}
                  touched={Boolean(touched.firstAlternativeDate)}
                  error={errors.firstAlternativeDate}
                />
              </Grid>
            )}
            {!values.isDirectBookingEnabled && (
              <Grid item xs={12} sm={6} mb={1}>
                <DatePicker
                  label={"Alternative date #2"}
                  fieldName="secondAlternativeDate"
                  value={values.secondAlternativeDate}
                  practiceData={practiceData}
                  touched={Boolean(touched.secondAlternativeDate)}
                  error={errors.secondAlternativeDate}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} mb={1} textAlign={"center"}>
          <Button
            onClick={() => {
              isValidStep(1).then((res) => {
                if (res === true) handleNext();
              });
            }}
            variant="contained"
            color="primary"
            sx={{ width: "130px" }}
          >
            Next
          </Button>

          <br />
          <br />
          {CancelBtn}
        </Grid>
      </Grid>
    </>
  );
};

export default AppointmentStep;
