import React from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  Alert,
  AlertTitle,
  Grid,
} from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {
  TAppointmentFormValues,
  TAppointmentReason,
  TPracticeData,
} from "../types";
import PreferredDoctor from "../components/PreferredDoctor";
import { FormikProps } from "formik";
import SmartAppointment from "../components/SmartAppointment";
import DatePicker from "../components/DatePicker";
import HourPicker from "../components/HourPicker";

type TAdjustDateModalProps = {
  isOpen: boolean;
  isSubmit: boolean;
  closeModal: () => void;
  practiceData: TPracticeData;
  apptRequestToken: string;
  appointmentReason: TAppointmentReason | null;
  values: TAppointmentFormValues;
  onSubmit: () => void;
} & Pick<
  FormikProps<TAppointmentFormValues>,
  | "values"
  | "touched"
  | "errors"
  | "status"
  | "handleChange"
  | "handleBlur"
  | "setFieldValue"
>;

const AdjustDateModal: React.FC<TAdjustDateModalProps> = ({
  isOpen,
  isSubmit,
  closeModal,
  practiceData,
  apptRequestToken,
  appointmentReason,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  onSubmit
}) => {
  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -75%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: "0px 3px 14px 3px #E8E8FF",
    borderRadius: 6,
    p: 4,
  };


  return (
    <Modal
      disableAutoFocus
      hideBackdrop
      open={isOpen}
    >
      <Box sx={style}>
        <Alert
          icon={
            <EventBusyIcon
              style={{
                width: "35px",
                height: "35px",
                color: "#677aff",
              }}
            />
          }
          severity="info"
          sx={{
            mt: 1,
            mb: 4,
            wordBreak: "break-word",
            alignItems: "center",
          }}
          className="theme-gray"
        >
          <AlertTitle>
            <Typography variant={"h2"} fontSize={14}>
              This appointment is no longer available.
            </Typography>
          </AlertTitle>
          <Typography>Please make another selection.</Typography>
        </Alert>

        <PreferredDoctor
          practiceData={practiceData}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          selectedAppointmentReason={appointmentReason}
          values={values}
        />

        <Grid container item gap={2} mt={3} sx={{ flexWrap: "nowrap" }}>
          {practiceData.isSmartAppointmentRequestsEnabled ? (
            <SmartAppointment
              apptRequestToken={apptRequestToken}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              values={values}
              touched={touched}
              errors={errors}
              handleBlur={handleBlur}
            />
          ) : (
            <>
              <Grid item xs={6} mb={1}>
                <DatePicker
                  label={`${
                    values.isDirectBookingEnabled ? "Pick" : "Preferred"
                  } date`}
                  fieldName="preferredDate"
                  value={values.preferredDate}
                  practiceData={practiceData}
                  touched={Boolean(touched.preferredDate)}
                  error={errors.preferredDate}
                />
              </Grid>
              <Grid item xs={6} mb={1}>
                <HourPicker
                  practiceData={practiceData}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          marginTop={4}
          item
          display={"flex"}
          xs={12}
          justifyContent={"center"}
        >
          <Button
            onClick={() => {isSubmit ? onSubmit(): closeModal()}}
            variant="contained"
            color="primary"
            sx={{ padding: "12px 30px" }}
          >
            {isSubmit ? "Book Appointment" : "Save"}
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AdjustDateModal;
