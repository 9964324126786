import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Container, Grid, Typography } from "@mui/material";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import {
  AppointmentFormResponses,
  TAppointmentInfo,
  TAppointmentForm,
  TFormField,
} from "./types";
import './style.scss';

interface FormBuilderProps {
  appointmentForm: TAppointmentForm;
  isActiveStepCompleted: boolean;
  practiceData: TAppointmentInfo,
  isSaving: boolean;
  handleCompleteStep: () => void;
  onSendClick: (
    formId: string,
    animalAppointmentConfirmationId: string,
    payload: AppointmentFormResponses[]
  ) => Promise<void>;
}
const FormBuilder: React.FC<FormBuilderProps> = ({
  appointmentForm,
  isActiveStepCompleted,
  practiceData,
  isSaving,
  handleCompleteStep,
  onSendClick,
}) => {
  const [elements, setElements] = useState<TFormField[]>(
    appointmentForm.fields
  );

  useEffect(() => {
    setElements(appointmentForm.fields);
  }, [appointmentForm]);

  const handleChange = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newElements = [...elements];

    let valueIndex;
    let updatedValue;

    newElements.forEach((field) => {
      const { type, id: fieldId } = field;
      if (id === fieldId.toString()) {
        switch (type) {
          case "checkbox":
            if (!field.value || !Array.isArray(field.value)) {
              field.value = [];
            }

            updatedValue = [...field.value];

            valueIndex = field.value.indexOf(event.target.value);
            if (valueIndex === -1) {
              updatedValue.push(event.target.value);
            } else {
              updatedValue.splice(valueIndex, 1);
            }
            field.value = updatedValue;
            break;
          default:
            field.value = event.target.value;
            break;
        }
      }
      setElements(newElements);
    });
  };

  const handleSend = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload = elements.map((element) => {
      let response = "No Response";
      const { id, label, value } = element;

      if (
        value !== undefined &&
        value !== null &&
        (Array.isArray(value) ? value.length > 0 : value.trim() !== "")
      ) {
        if (Array.isArray(value) && value.length > 0) {
          response = value.join(", ");
        } else {
          response = value as string;
        }
      }
      return {
        id,
        label,
        response,
      };
    });

    await onSendClick(
      appointmentForm.id,
      appointmentForm.animalAppointmentConfirmationId,
      payload
    );
    handleCompleteStep();
  };

  return (
    <Container maxWidth={"sm"}>
      <>
        <Box textAlign={'center'} mt={4}>
          <img className="appt-form-logo" src={practiceData.practiceLogoUrl} alt={practiceData.clientName} />
        </Box>
        <Typography
          variant={"h1"}
          fontSize="32px"
          fontWeight={500}
          align="center"
          mb={3}
          mt={5}
        >
          {appointmentForm.name}
        </Typography>
        {appointmentForm.description && (
          <Alert
            icon={false}
            severity="info"
            sx={{ mt: 3 }}
            className="theme-gray"
          >
            {appointmentForm.description}
          </Alert>
        )}
        <form onSubmit={handleSend}>
          <Grid container spacing={"18px"} mt={3} mb={10}>
            {elements
              ? elements.map((field) => (
                  <Grid
                    key={`${appointmentForm.id}${field?.id}`}
                    item
                    xs={12}
                    sx={{ m: 1 }}
                  >
                    <DynamicForm
                      key={`${appointmentForm.id}-field-${field.id}`}
                      fieldType={field.type}
                      fieldId={field.id + ""}
                      fieldLabel={field.label}
                      fieldPlaceholder={""}
                      fieldValue={field.value}
                      fieldHelperText={field.description}
                      fieldOptions={field.options}
                      fieldMandatory={field.mandatory}
                      handleChange={handleChange}
                      isActiveStepCompleted={isActiveStepCompleted}
                    />
                  </Grid>
                ))
              : null}
            <Grid item xs={12} mb={1} textAlign={"center"}>
              <Button
                type="submit"
                color="primary"
                size="large"
                sx={{ width: "145px" }}
                variant="contained"
                disabled={isActiveStepCompleted || isSaving}
              >
                {isSaving ? "Saving" : "Send"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Container>
  );
};

export default FormBuilder;
