export const isEmailValid = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const isPhoneNumberValid = (phone: string) => {
  const re = /^\d{3}[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return re.test(phone);
};

export const isPhoneNumberEncrypted = (phone: string) => {
  const pattern = /^\*{3}-\*{3}-\d{4}$/;
  return pattern.test(phone);
};
