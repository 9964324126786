import React, { useState } from "react";
import {
  ThemeProvider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { theme } from "./widget.theme";
import { TFeedbackFormData } from "./types";
import FeedbackForm from "./FeedbackForm";
import FeedbackSuccess from "./FeedbackSuccess";
import useFeedback from "./hooks/useFeedback";

const initialState = {
  rating: 0,
  message: ''
}

export default function FeedbackPage() {
  const { feedbackToken } = useParams<{ feedbackToken: string }>();
  const [data, setData] = useState<TFeedbackFormData>(initialState);

  const {isSuccessful, formErrors, submitFeedbackHandler, feedbackResponse} = useFeedback();
  
  if (!feedbackToken) return <>Invalid request</>;

  const onSubmitHandler = async () => {
    return submitFeedbackHandler(feedbackToken, data);
  }

  return (
    <ThemeProvider theme={theme}>
      {!isSuccessful && <FeedbackForm data={data} setData={setData} formErrors={formErrors} onSubmitHandler={onSubmitHandler} />}
      {isSuccessful && <FeedbackSuccess data={data} feedbackResponse={feedbackResponse}/>}
    </ThemeProvider>
  );
}
