import axiosClient from "../../../config/axios";
import { TApptRequestSubmitPayload, TAvailableDatesResponse, TPracticeData, TValidateAppointmentPayload } from "../types";

export function getPracticeDetails(apptToken: string): Promise<TPracticeData> {
  return axiosClient.get(`/api/appointment-requests-customizations/${apptToken}`).then(res => res.data);
}

export function submitAppointmentRequest(apptToken: string, payload: TApptRequestSubmitPayload) {
  return axiosClient.post(`/api/appointment-requests/${apptToken}`, payload);
}

export async function getAvailableDatesRequest(
  apptRequestToken: string,
  startDate: string,
  endDate: string,
  appointmentReasonId?: string,
  practiceDoctorId?: string
): Promise<TAvailableDatesResponse> {
  return axiosClient.get(`/api/appointment-requests-customizations/${apptRequestToken}/available-times`, {
    params: {
      startDate,
      endDate,
      appointmentReasonId,
      practiceDoctorId
    }
  });
}

export function validateAppointmentRequest(apptToken: string, payload: TValidateAppointmentPayload) {
  return axiosClient.post(`/api/appointment-requests/${apptToken}/is-valid`, payload);
}