import { useRef, useState } from "react";
import { getAvailableDatesRequest } from "../api/appointment";
import dayjs from "dayjs";
import { getFirstDayOfMonth, getLastDayOfMonth } from "../../../utils/date.util";
import { TAvailableHours, TYearMonthAvailability } from "../types";
  
export const NO_PREFERRED_DOCTOR = "No preference";

const useSmartDateAPI = (apptRequestToken: string) => {
    const [isFetchingDates, setIsFetchingDates] = useState<boolean>(false);
    const datesRef = useRef<TYearMonthAvailability>({});

    const getAvailableDates = async (selectedDate: Date, appointmentReasonId?: string, practiceDoctorId?: string) => {
        const key = generateKeyForCache(selectedDate, appointmentReasonId, practiceDoctorId);
        if (datesRef.current[key]) {
            return datesRef.current[key];
        } else {
            setIsFetchingDates(true);
            const firstDayOfMonth = getFirstDayOfMonth(selectedDate);
            const lastDayOfMonth = getLastDayOfMonth(selectedDate);
            const selectedDoctor = (!practiceDoctorId || practiceDoctorId === NO_PREFERRED_DOCTOR) ? undefined : practiceDoctorId;
            const response = await getAvailableDatesRequest(apptRequestToken, firstDayOfMonth, lastDayOfMonth, appointmentReasonId, selectedDoctor);
            if(Object.keys(response.data).length > 0){
                datesRef.current[key] = response.data;
            }
            setIsFetchingDates(false);
        }
    };

    const isDayAvailable = (date: Date, appointmentReasonId?: string, practiceDoctorId?: string) => {
        if (Object.keys(datesRef).length === 0) {
            return false;
        }
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        const key = generateKeyForCache(date, appointmentReasonId, practiceDoctorId);
        if (datesRef.current[key]) {
            const allDatesInThatMonth = getAvailableDatesInMonth(key)
            return allDatesInThatMonth.includes(formattedDate);
        }
        return false;
    };

    const generateKeyForCache = (date: Date, appointmentReasonId?: string, practiceDoctorId?: string): string => {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const appointmentReasonStr = appointmentReasonId ? `-${appointmentReasonId}` : "";
      const practiceDoctorIdStr = practiceDoctorId ? `-${practiceDoctorId}` : "";
      const key = `${year}-${month}${appointmentReasonStr}${practiceDoctorIdStr}`;
      return key;
    };

    const getAvailableDatesInMonth = (monthKey: string) => {
      return datesRef.current[monthKey]
          ? Object.keys(datesRef.current[monthKey])
                .filter(dayKey => {
                    const dayEntry = datesRef.current[monthKey][dayKey];
                    return !dayEntry.isClosed && dayEntry.availableTimes?.length > 0;
                })
          : [];
    };

    const getAvailableTimes = (selectedDate: Date, appointmentReasonId?: string, practiceDoctorId?: string): TAvailableHours[] => {
        if (!selectedDate) {
            return []
        }

        const formattedDate = dayjs(selectedDate).toDate();
        const key = generateKeyForCache(formattedDate, appointmentReasonId, practiceDoctorId);
        if (datesRef.current[key]) {
            const allDays = datesRef.current[key];
            const formattedDate2 = dayjs(selectedDate).format('YYYY-MM-DD');

            if (allDays[formattedDate2].availableTimes && allDays[formattedDate2].availableTimes.length > 0) {
                return allDays[formattedDate2].availableTimes.map((availableTime: TAvailableHours) => availableTime)
            }
        }

        return []
    }

    return {
        getAvailableDates,
        getAvailableTimes,
        isDayAvailable,
        isFetchingDates,
    };
};

export default useSmartDateAPI;
