import { useElements, useStripe as useStripeClient } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

type TStripeBillingDetails = {
  name: string
}

const useStripe = () => {
  const elements = useElements();
  const stripe = useStripeClient();

  const [isCardInfoComplete, setIsCardInfoComplete] = useState(false);

  useEffect(() => {
    if (!elements || !stripe) return;
    const cardElement = elements.getElement("card");
    if (!cardElement) return;


    const handleChange = (event: StripeCardElementChangeEvent) => {
      if (event.complete) {
        setIsCardInfoComplete(true)
      } else if (event.error) {
        setIsCardInfoComplete(false)
      }
    };

    cardElement.on('change', handleChange);

    return () => {
      cardElement.off('change', handleChange);
    };
  }, [elements]);

  const generatePaymentMethod = async (billingDetails?: TStripeBillingDetails) => {
    if (!elements || !stripe) return;

    const cardElement = elements.getElement("card");

    if (!cardElement) return;

    const response = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: billingDetails
    });

    if (response.error) {
      return;
    }

    return response;
  };

  const clearCardDetails = () => {
    if (!elements || !stripe) return;

    const cardElement = elements.getElement("card");
    if (!cardElement) return;
    
    cardElement.clear();
  };

  return { generatePaymentMethod, clearCardDetails, isCardInfoComplete };
};

export default useStripe;