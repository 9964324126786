import dayjs from "dayjs";
import { isBeforeDate, isDateAHoliday, weekDays } from "../../utils/date.util";
import { TBusinessHours } from "./steps/AppointmentStep";

export const fillPetsArray = (n: number, pets: { name: string, appointmentReasons: string[] }[]): { name: string, appointmentReasons: string[] }[] => {
  const result: { name: string, appointmentReasons: string[] }[] = [];
  for (let i = 0; i < n; i++) {
    const pet = pets[i] || { name: "", appointmentReasons: [] };
    result.push({ name: pet.name || "", appointmentReasons: pet.appointmentReasons || [] });
  }
  
  return result;
};

export const isWorkingDay = (date: Date, businessHours: TBusinessHours) => {
  const day = weekDays[date.getDay()];
  if (businessHours.size < 1) return true;
  return !day || businessHours.has(day);
};

export const getNextWorkingDay = (
  nthDay: number,
  businessHours: TBusinessHours,
  holidays: Set<string>
): Date => {
  if (!Number.isInteger(nthDay) || nthDay <= 0) {
    throw new Error("nthDay should be a positive integer.");
  }
  let today = dayjs();
  let dayCount = 0;
  const maxIterations = 100;

  while (dayCount < nthDay && dayCount < maxIterations) {
    today = today.add(1, "day");

    if (
      isWorkingDay(today.toDate(), businessHours) &&
      !isDateAHoliday(today.toDate(), holidays)
    ) {
      dayCount++;
    }
  }
  return today.toDate();
}

export const isDateAvailable = (
  date: Date,
  businessHours: TBusinessHours,
  holidays: Set<string>,
  nextWorkingDate: Date
) => {
  return !(
    isDateAHoliday(date, holidays) ||
    isBeforeDate(date, nextWorkingDate) ||
    (businessHours.size > 0 && !isWorkingDay(date, businessHours))
  );
};