import { AxiosResponse } from "axios";
import axiosClient from "../../../config/axios";
import { AppointmentConfirmationPayload } from "../types";

export const fetchAppointmentForm = (emailToken: string): Promise<AxiosResponse> =>
    axiosClient.get(`/api/appointment-forms/${emailToken}`);

export const postAppointmentForm = (form_id: string, payload: AppointmentConfirmationPayload): Promise<AxiosResponse> =>
    axiosClient.post(`/api/appointment-forms/${form_id}/responses`, payload);

