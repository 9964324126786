import React from "react";
import { Select, MenuItem } from "@mui/material";
import type { SelectProps } from "@mui/material/Select";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { formatTimeTo12h } from "../utils/date.util";
import { selectMenuProps } from "../utils/select-menu-props.util";

interface HourSelectProps {
  value: string; // Value of selected time (24-hour format)
  interval?: number; // Time interval in minutes (default: 30)
  startTime?: string; // Minimum selectable time (default: '00:00')
  endTime?: string; // Maximum selectable time (default: '23:30')
  fullWidth?: boolean;
  selectProps?: Omit<SelectProps, "value">;
}
type TTimeSlot = { value: string; label: string };
function generateTimeArray(interval: number, startTime: string, endTime: string): TTimeSlot[] {
  const timeArray: TTimeSlot[] = [];

  // Parse start and end time
  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  // Generate time slots
  let currentHour = startHour;
  let currentMinute = startMinute;

  while (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute)) {
    const hourFormatted = String(currentHour).padStart(2, "0");
    const minuteFormatted = String(currentMinute).padStart(2, "0");

    const timeSlot = `${hourFormatted}:${minuteFormatted}`;
    timeArray.push({
      value: timeSlot, // stored, used and sent to server in 24 hour format
      label: formatTimeTo12h(timeSlot), // displayed to user in 12 hour format
    });

    // Increment current time
    currentMinute += interval;

    if (currentMinute >= 60) {
      currentMinute %= 60;
      currentHour += 1;
    }
  }

  return timeArray;
}

const HourSelect: React.FC<HourSelectProps> = ({
  value = "00:00",
  interval = 30,
  startTime = "00:00",
  endTime = "23:59",
  fullWidth = false,
  selectProps = {},
}) => {
  const hours = generateTimeArray(interval, startTime, endTime);

  return (
    <Select
      IconComponent={AccessTimeIcon}
      value={value}
      fullWidth={fullWidth}
      {...selectProps}
      MenuProps={{
        ...selectMenuProps,
        style: {
          maxHeight: 205,
        },
      }}
    >
      {hours.map((hour, index) => (
        <MenuItem key={index} value={hour.value}>
          {hour.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default HourSelect;
