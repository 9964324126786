import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../../config";

type TStripeConfigWrapper = {
  children: React.ReactNode;
};

const stripePromise = loadStripe(
  STRIPE_PUBLISHABLE_KEY,
  {
    betas: ["us_bank_account_beta_2"],
    apiVersion: "2020-08-27; us_bank_account_beta=v2",
  }
);

const StripeWrapper: React.FC<TStripeConfigWrapper> = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);

export default StripeWrapper;
