import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          borderRadius: "7px",
          fontSize: "14px",
          fontWeight: 700,
          boxShadow: "none",
          textTransform: "none",
          "&, &:hover": {
            background: "#5B70FF",
          },
          "&.MuiButton-sizeLarge": {
            paddingTop: 10,
            paddingBottom: 10,
          },
        },
      },
    },
  },
});
