import axiosClient from "../../../config/axios";
import { TClient } from "../types";

interface IClientSearchParams {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

type TClientSearchApiResponse = {
  success: boolean;
  data: {
    clients: TClient[];
  };
};


export async function lookupClient (
  apptRequestToken: string, 
  firstName?: string, 
  lastName?: string, 
  email?: string, 
  phone?: string
): Promise<TClientSearchApiResponse> {
  const params: IClientSearchParams = {};

  if (firstName) {
    params.firstName = firstName;
  }
  if (lastName) {
    params.lastName = lastName;
  }
  if (email) {
    params.email = email;
  }
  if (phone) {
    params.phoneNumber = phone;
  }
  return axiosClient.get(`/api/practices/${apptRequestToken}/clients/search`, { params });
}
