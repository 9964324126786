import { FormikProps, withFormik } from "formik";
import * as Yup from "yup";
import { TApptRequestSubmitPayload, TAppointmentFormValues, TPracticeData } from "./types";
import { submitAppointmentRequest } from "./api/appointment";
import { NO_PREFERRED_DOCTOR } from "./hooks/useSmartDateAPI";
import { formatIsoDate } from "../../utils/date.util";

type ParentProps = {
  practiceData: TPracticeData;
  apptRequestToken: string;
};

interface MyFormProps extends FormikProps<TAppointmentFormValues>, ParentProps {}

export const attachFormik = withFormik({
  mapPropsToValues: ({
    firstName,
    lastName,
    phone,
    email,
    isNewClient,
    preferredDate,
    preferredTime,
    firstAlternativeDate,
    secondAlternativeDate,
    preferredDoctor,
    petsCount,
    appointmentReason,
    pets,
    comment,
    species,
    isDirectBookingEnabled,
  }: Partial<TAppointmentFormValues> & ParentProps) => {
    return {
      firstName: firstName || "",
      lastName: lastName || "",
      phone: phone || "",
      isPhoneTextable: true,
      email: email || "",
      isNewClient: isNewClient,
      preferredDate: preferredDate || null,
      preferredTime: preferredTime || "",
      firstAlternativeDate: firstAlternativeDate || null,
      secondAlternativeDate: secondAlternativeDate || null,
      preferredDoctor: preferredDoctor || "",
      petsCount: petsCount || "1",
      pets: pets || [],
      appointmentReason: appointmentReason || "",
      comment: comment || "",
      species: species || "",
      isDirectBookingEnabled: isDirectBookingEnabled || false,
    };
  },
  validateOnBlur: true,
  validationSchema: (props: MyFormProps) => {
    return Yup.object().shape({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      phone: Yup.string()
        .required("Required")
        .test("is-valid-phone", "Invalid phone number", (value) => {
          const plainNumberPattern = /^\d{10}$/;
          const redactedPattern = /^\*\*\*-\*\*\*-\d{4}$/;

          return plainNumberPattern.test(value) || redactedPattern.test(value);
        }),
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
      preferredDate: Yup.date().nullable().required("Required"),
      preferredTime: Yup.string().required("Required"),
      firstAlternativeDate: Yup.date().nullable(),
      secondAlternativeDate: Yup.date().nullable(),
      preferredDoctor: Yup.string().when([], (value, schema) => {
        return props.practiceData.doctorNames?.length > 0
          ? schema.required("Required")
          : schema;
      }),
      petsCount: Yup.string().required("Required"),
      pets: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required("Required").test("is-valid-pet-name", "You can only book appointments for one pet at a time.\nSpecial characters, such as !, &, $, %, +, are not allowed.", 
            (value, context) => {
              const { from }: any = context;
              const isDirectBookingEnabled = from[1]?.value?.isDirectBookingEnabled;
              if (isDirectBookingEnabled) {
                const regex = /^(?!.*[!$%&,+/])(?!.*\band\b).+$/;
                return regex.test(value.toLowerCase());
              } else {
                return true;
              }
            }),
          })
        )
        .required("Required")
        .when("petsCount", (values, schema) => {
          const val = +(values[0] || "").replace("+", "");
          return schema.min(val, "Please fill in all pet details");
        }),
        appointmentReason: Yup.string().required("Required"),
      comment: Yup.string().max(250, "Comment must be at most 250 characters"),
      species: Yup.string(),
    });
  },

  handleSubmit: (values, { props, setSubmitting, setStatus }) => {
    setSubmitting(true);
    const payload = { ...values };

    payload.phone = String(values.phone);
    payload.pets = values.pets.map(p => {
      p.appointmentReasons = values.appointmentReason ? [values.appointmentReason] : [];
      return p;
    })

    if(props.practiceData.isSmartAppointmentRequestsEnabled){
      if(values.preferredDoctor !== NO_PREFERRED_DOCTOR){
        payload.preferredDoctor = props.practiceData.doctorList.find(doctor => doctor.id === values.preferredDoctor)?.displayName || ''
      }
    }

    return submitAppointmentRequest(props.apptRequestToken, {
      ...payload,
      preferredDate: formatIsoDate(values?.preferredDate),
      firstAlternativeDate: formatIsoDate(values?.firstAlternativeDate),
      secondAlternativeDate: formatIsoDate(values?.secondAlternativeDate),       
    } as TApptRequestSubmitPayload)
      .then((res) => {
        setStatus({
          success: true,
          data: res,
          message: `Successfully submitted`,
        });
      })
      .catch((err) => {
        setStatus({
          success: false,
          data: err,
          message: err.message,
        });
        return Promise.reject(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  },
});
