import React from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { StripeCardElement } from "../../../components/Stripe/StripeCardElement";
import { FormikProps } from "formik";
import { TAppointmentFormValues, TAppointmentReason, TAppointmentReasonSettings } from "../types";

type TCardStepProps = {
  handleSubmitFormDirectBooking: () => Promise<void>;
  appointmentReason: TAppointmentReason | null,
  practiceName: string,
  isCardInfoComplete: boolean,
} & Pick<FormikProps<TAppointmentFormValues>, "status" | "isSubmitting">;

const getPaymentPolicy = (practiceName: string, settings?: TAppointmentReasonSettings) => {
  const isNoShowFeeRequired = settings?.isNoShowFeeRequired ?? false;
  const isDepositRequired = settings?.isDepositRequired ?? false;
  const noShowFeeAmount = settings?.noShowFeeAmount;
  const depositAmount = settings?.depositAmount;

  if (isNoShowFeeRequired && !isDepositRequired) {
    return `By proceeding, you authorize ${practiceName} to charge your card for future payments, including a no-show fee of $${noShowFeeAmount} if you fail to show up for your appointment or cancel less than 24 hours before your appointment.`;
  }

  if (!isNoShowFeeRequired && isDepositRequired) {
    return `By proceeding, you authorize ${practiceName} to charge your card for future payments, including a non-refundable deposit of $${depositAmount} that will go towards the cost of your services.  The non-refundable deposit will be charged at the time of booking. More information about ${practiceName}'s Cancellation Policy can be found on ${practiceName}'s website.`;
  }

  if (isNoShowFeeRequired && isDepositRequired) {
    return `By proceeding, you authorize ${practiceName} to charge your card for future payments, including a no-show fee of $${noShowFeeAmount} if you fail to show up for your appointment or cancel less than 24 hours before your appointment and a non-refundable deposit of $${depositAmount} that will go towards the cost of your services.`;
  }

  if (!isNoShowFeeRequired && !isDepositRequired) {
    return `By proceeding, you allow ${practiceName} to store your card on file. You may use this card to pay for services received in the future. `;
  }

};

const getSubtitle = (settings?: TAppointmentReasonSettings) => {
  if (settings?.isAutomaticPaymentEnabled) {
    return `Enter your card details below. A $${settings?.depositAmount} non-refundable deposit will be charged to your card to complete your booking.`;
  }
  return "To secure your spot, payment information is needed. Enter your card details below.";
};

const CardStep: React.FC<TCardStepProps> = ({
  handleSubmitFormDirectBooking,
  status,
  isSubmitting,
  appointmentReason,
  practiceName,
  isCardInfoComplete
}) => {
  const PAYMENT_METHOD_ERROR_CODE = "E24010";

  const isPaymentMethodError = () => {
    return status?.data?.data?.code === PAYMENT_METHOD_ERROR_CODE ?? false;
  };

  const showError = () => {
    let errorMessage =
      "There was some problem submitting the form. Your appointment cannot be booked online at this time.";

    if (isPaymentMethodError()) {
      errorMessage =
        "Your appointment could not be booked. Please try again with a different payment method.";
    }

    return (
      <Grid item xs={12} mb={1} mt={6}>
        <Grid item xs={12} justifyItems={"center"} ml="auto" mr="auto">
          <Alert severity="error">{errorMessage}</Alert>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Box maxWidth={430}>
        <Typography variant="h2" mt={2}>
          Complete booking
        </Typography>
        <Typography variant="body2" mt={3}>
          {getSubtitle(appointmentReason?.settings)}
        </Typography>
      </Box>
      <Box fontSize={15} mt={4}>
        <StripeCardElement />
      </Box>
      <Typography variant="body2" mt={5}>
        Clicking <strong>Book Appointment</strong> will instantly schedule your
        appointment.
      </Typography>
      <Button
        onClick={() => handleSubmitFormDirectBooking()}
        variant="contained"
        color="primary"
        sx={{ width: "230px", paddingY: "12px", marginTop: 5 }}
        disabled={(status?.data && status.success === false && !isPaymentMethodError()) || isSubmitting || !isCardInfoComplete}
      >
        {isSubmitting ? (
          <CircularProgress sx={{ color: "#fff" }} size={24} />
        ) : (
          "Book Appointment"
        )}
      </Button>
      <div style={{ marginTop: 40, marginBottom: 40 }}>
        <Typography fontSize={9} maxWidth={320} lineHeight={1.4}>
          {getPaymentPolicy(practiceName, appointmentReason?.settings)}
        </Typography>
      </div>
      {status?.data && status.success === false && showError()}
    </>
  );
};

export default CardStep;
