import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    body1: {
      fontSize: '12px',
      color: '#1D1C2F',
    },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",sans-serif',
    
    h2: {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.theme-gray': {
            background: '#F4F7FC',
            color: '#617FBB',
            borderRadius: '5px',
            padding: '7px 15px'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          borderRadius: '7px',
          fontSize: '14px',
          fontWeight: 700,
          boxShadow: 'none',
          textTransform: 'none',
          '&, &:hover': {
            background: '#5B70FF',
          },
          '&.MuiButton-sizeLarge': {
            paddingTop: 10,
            paddingBottom: 10,
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#EAEDF4',
          border: 0,
          fontSize: '12px',
          '& .MuiInputBase-input': {
            paddingTop: '11px',
            paddingBottom: '11px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
            borderRadius: '5px'
          },
          '&.MuiInputBase-multiline textarea': {
            paddingTop: '0',
            paddingBottom: 0,
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }

        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#1D1C2F',
          transform: 'translate(0, -1.5px) scale(1)',
          marginBottom: 2,
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {

        }
      }
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderLeft: 0,
          marginLeft: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }
      }
    },
    MuiStep: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
        }
      }
    }
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       border: 0,
    //     }
    //   },
    // }
  }
});
