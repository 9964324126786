import React from "react";
import {
  TAppointmentFormValues,
  TAppointmentReason,
  TClient,
  TPracticeData,
} from "../types";
import {
  Alert,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { selectMenuProps } from "../../../utils/select-menu-props.util";
import { FormikProps } from "formik";
import AlertIcon from "../assets/AlertIcon";

type TClientStepProps = {
  CancelBtn: React.ReactNode;
  handleNext: () => void;
  isValidStep: (arg: number) => Promise<boolean>;
  clientLookup: TClient | null;
  handleAppointmentReasonSelectChange: (
    event: SelectChangeEvent<string>
  ) => void;
  appointmentReason: TAppointmentReason | null;
  practiceData: TPracticeData;
} & Pick<
  FormikProps<TAppointmentFormValues>,
  | "values"
  | "touched"
  | "errors"
  | "status"
  | "handleChange"
  | "handleBlur"
  | "setFieldValue"
>;

const ClientStep: React.FC<TClientStepProps> = ({
  CancelBtn,
  handleNext,
  isValidStep,
  clientLookup,
  handleAppointmentReasonSelectChange,
  appointmentReason,
  practiceData,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue,
}) => {
  const appointmentReasonList: TAppointmentReason[] =
    practiceData.appointmentReasonList || [];

  return (
    <>
      {practiceData.headerCustomCopy && (
        <Alert
          icon={
            <AlertIcon
              style={{
                width: "45px",
                height: "45px",
                color: "red",
              }}
            />
          }
          severity="info"
          sx={{
            mt: 3,
            mb: 4,
            wordBreak: "break-word",
            alignItems: "center",
          }}
          className="theme-gray"
        >
          {practiceData.headerCustomCopy}
        </Alert>
      )}
      <Typography fontSize={24} mt={2}>
        Enter your information
      </Typography>
      <Grid container spacing={"18px"} mt={3}>
        <Grid item xs={12} sm={6} mb={1}>
          <InputLabel shrink>First name</InputLabel>
          <TextField
            fullWidth
            name={"firstName"}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.firstName ? errors.firstName : ""}
            error={touched.firstName && Boolean(errors.firstName)}
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={1}>
          <InputLabel shrink>Last name</InputLabel>
          <TextField
            fullWidth
            name={"lastName"}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.lastName ? errors.lastName : ""}
            error={touched.lastName && Boolean(errors.lastName)}
          />
        </Grid>

        <Grid item xs={12} mb={1}>
          <InputLabel shrink>Email</InputLabel>
          <TextField
            fullWidth
            name={"email"}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
          />
        </Grid>

        <Grid item xs={12} sm={6} mb={1}>
          <InputLabel shrink>Phone</InputLabel>
          <TextField
            fullWidth
            name={"phone"}
            value={values.phone}
            onChange={(e) => {
              const newValue = e.target.value.replace(/[^0-9]/g, "");
              setFieldValue("phone", newValue);
            }}
            onBlur={handleBlur}
            helperText={touched.phone ? errors.phone : ""}
            error={touched.phone && Boolean(errors.phone)}
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={1}>
          <InputLabel shrink>Reason for appointment</InputLabel>
          <Select
            MenuProps={{
              ...selectMenuProps,
            }}
            fullWidth
            name={"appointmentReason"}
            value={appointmentReason ? appointmentReason.id : ""}
            onChange={handleAppointmentReasonSelectChange}
            onBlur={handleBlur}
            error={
              touched.appointmentReason && Boolean(errors.appointmentReason)
            }
          >
            {appointmentReasonList.map(
              (appointmentReason: TAppointmentReason) => (
                <MenuItem
                  key={appointmentReason.id}
                  value={appointmentReason.id}
                >
                  {appointmentReason.reason}
                </MenuItem>
              )
            )}
          </Select>
          {touched.appointmentReason && Boolean(errors.appointmentReason) && (
            <FormHelperText error>{errors.appointmentReason}</FormHelperText>
          )}
        </Grid>

        {clientLookup && clientLookup.isActive && (
          <Grid item xs={12} mb={1}>
            <Alert
              icon={false}
              severity="info"
              sx={{
                wordBreak: "break-word",
              }}
              className="theme-gray"
            >
              {`Welcome back, ${clientLookup.firstName}! We found your information on file.`}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} mb={1} textAlign={"center"}>
          <Button
            onClick={() => {
              isValidStep(0).then((res) => {
                if (res === true) handleNext();
              });
            }}
            variant="contained"
            color="primary"
            sx={{ width: "130px" }}
          >
            Next
          </Button>

          <br />
          <br />
          {CancelBtn}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientStep;
