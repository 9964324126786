import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  Typography,
} from "@mui/material";
import { DatePicker, pickersLayoutClasses } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import {
  calculateBusinessDaysFromToday,
  getNYearsFromToday,
} from "../../../utils/date.util";
import { FormikErrors, FormikTouched } from "formik";

type TSmartDatePickerProps = {
  isInitialLoading: boolean;
  allDatesDisabled: boolean;
  isLoading: boolean;
  handleChange: (newValue: Dayjs | null) => void;
  value: Dayjs | null;
  touched: FormikTouched<Dayjs> | undefined;
  shouldDisableDate: (date: Dayjs) => boolean;
  onMonthChange: (monthStart: Dayjs) => void;
  inputLabel: string;
  error: FormikErrors<Dayjs> | undefined;
};

const SmartDatePicker = ({
  isInitialLoading,
  allDatesDisabled,
  isLoading,
  handleChange,
  value,
  touched,
  shouldDisableDate,
  onMonthChange,
  inputLabel,
  error,
}: TSmartDatePickerProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const fiveBusinessDaysFromToday = calculateBusinessDaysFromToday(5);
  const oneYearFromToday = getNYearsFromToday(1);

  const CustomCalendarWindow = React.useCallback(
    () => (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            height: 5,
            gridColumn: 2,
            gridRow: 1,
            marginRight: 3,
            minHeight: "30px",
            alignItems: "end",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "row" }}>
            {isLoading && (
              <CircularProgress size="1rem" sx={{ marginRight: "10px" }} />
            )}
            {isLoading && <Typography>Fetching available dates</Typography>}
            {!isLoading && allDatesDisabled && (
              <Typography>No available dates for this month.</Typography>
            )}
          </Grid>
        </Box>
      </React.Fragment>
    ),
    [isLoading, allDatesDisabled]
  );

  return (
    <Grid item xs={6} mb={1}>
      <InputLabel shrink>{inputLabel}</InputLabel>
      {isInitialLoading ? (
        <LinearProgress
          sx={{
            height: "38px",
            borderRadius: "3px",
            backgroundColor: "#eaedf4",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#b1b9cb",
              borderRadius: "3px",
            },
          }}
        />
      ) : (
        <DatePicker
          disablePast
          open={isDatePickerOpen}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          onChange={handleChange}
          value={value}
          shouldDisableDate={shouldDisableDate}
          minDate={fiveBusinessDaysFromToday}
          maxDate={oneYearFromToday}
          onMonthChange={async (date) => {
            return await onMonthChange(date);
          }}
          onYearChange={async (date) => {
            return await onMonthChange(date);
          }}
          slots={{ actionBar: CustomCalendarWindow }}
          sx={{
            "& .MuiDayCalendar-monthContainer": {
              visibility: "hidden",
            },
            "& .MuiPickersCalendarHeader-root": {
              cursor: "not-allowed",
              pointerEvents: "none",
            },
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              error: touched && Boolean(error),
              // helperText: touched && String(error),
              onClick: (e) => {
                e.preventDefault();
                setIsDatePickerOpen(true);
              },
            },
            layout: {
              sx: {
                "& .MuiDayCalendar-monthContainer": {
                  "& .MuiButtonBase-root": {
                    cursor: isLoading ? "not-allowed" : "inherit",
                    pointerEvents: isLoading ? "none" : "inherit",
                    color: isLoading ? "lightgray !important" : "inherit",
                  },
                },
                "& .MuiPickersCalendarHeader-labelContainer": {
                  cursor: "not-allowed",
                  pointerEvents: "none",
                  "& .MuiPickersCalendarHeader-switchViewButton": {
                    visibility: "hidden",
                  },
                },
              },
            },
            actionBar: {
              sx: {
                [`.${pickersLayoutClasses.actionBar}`]: {
                  gridColumn: 1,
                  gridRow: 1,
                },
              },
            },
          }}
        />
      )}
      {touched && error && (
        <FormHelperText error>{error as string}</FormHelperText>
      )}
    </Grid>
  );
};

export default SmartDatePicker;
