import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IDynamicFormProps } from "./types";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";

const DynamicCheckbox: React.FC<IDynamicFormProps> = ({
  fieldId,
  fieldValue,
  fieldLabel,
  fieldOptions,
  fieldHelperText,
  fieldDisabled,
  fieldMandatory,
  handleChange,
}) => {
  const [isRequired, setIsRequired] = useState<boolean>(fieldMandatory);

  useEffect(() => {
    if (fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0) {
      setIsRequired(false);
    } else if (
      fieldMandatory &&
      fieldValue &&
      Array.isArray(fieldValue) &&
      fieldValue.length === 0
    ) {
      setIsRequired(true);
    }
  }, [fieldValue]);

  return (
    <FormControl
      disabled={fieldDisabled}
      id={fieldId}
      required={fieldMandatory}
    >
      <FormLabel>{fieldLabel}</FormLabel>
      <FormGroup id={fieldId}>
        {fieldOptions &&
          fieldOptions.length > 0 &&
          fieldOptions.map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  value={option}
                  required={isRequired}
                  checked={
                    Array.isArray(fieldValue) && fieldValue?.includes(option)
                  }
                  onChange={(event) => handleChange(fieldId, event)}
                />
              }
              label={option}
              sx={{
                "& .MuiFormControlLabel-asterisk": {
                  visibility: "hidden",
                },
              }}
            />
          ))}
      </FormGroup>
      <FormHelperText>{fieldHelperText}</FormHelperText>
    </FormControl>
  );
};

export default DynamicCheckbox;
