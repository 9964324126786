import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

// Note
// We assume all incoming time will be formatted in HH:mm format, with padded 0s
// Eg: It will be 07:45 and not 7:45


export function formatTimeTo12h(time: string) {
  const parsedTime = dayjs(time, 'HH:mm');
  return parsedTime.format('hh:mm A');
}

// Generate an array of dates for the current month
export function getAllDaysInAMonth(date: Date) {

  const monthDates = [];
  const firstDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  );

  let currentDateIterator = firstDayOfMonth;

  while (currentDateIterator <= lastDayOfMonth) {
    monthDates.push(currentDateIterator);
    currentDateIterator = new Date(
      currentDateIterator.getFullYear(),
      currentDateIterator.getMonth(),
      currentDateIterator.getDate() + 1
    );
  }

  return monthDates;
}

export const calculateBusinessDaysFromToday = (days: number) => {
  const today = dayjs();
  let businessDays = 0;
  let currentDate = today;

  while (businessDays < days) {
    currentDate = currentDate.add(1, 'day');
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      businessDays++;
    }
  }
  return currentDate;
}

export const isDateWithinAWeek = (date: Date) => {
  const oneWeekFromToday = dayjs().add(7, "day").startOf("day").toDate();
  return date <= oneWeekFromToday;
};

export const isDateAHoliday = (date: Date, holidays: Set<string>) => {
  const ddate = dayjs(date);
  const formattedDate = ddate.format("MM/DD/YYYY");
  return holidays.has(formattedDate);
};

/**
 * Checks if a given date is before another date.
 */
export const isBeforeDate = (date: Date, beforeDate?: Date) => {
  const someday = beforeDate || new Date();
  someday.setHours(0, 0, 0, 0);
  return date <= someday;
};

export const getFirstDayOfMonth = (date: Date) => dayjs(date).startOf("month").format('YYYY-MM-DD');
export const getLastDayOfMonth = (date: Date) => dayjs(date).endOf("month").format('YYYY-MM-DD');

export const getNYearsFromToday = (numberOfYears: number) => dayjs().add(numberOfYears, "year");

export const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

/**
 * Remove the timezone and formats a given date to "YYYY-MM-DDT00:00:00Z" or returns undefined if the date is not defined.
 *
 * @param date - The date to format.
 * @returns The formatted date string or undefined.
 */
export const formatIsoDate = (date: Dayjs | null): string | undefined => {
  if (!date) {
    return undefined;
  }
  return date.format("YYYY-MM-DD") + "T00:00:00Z";
};