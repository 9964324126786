import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LoaderSpinner from "../../components/LoaderSpinner/LoaderSpinner";
import useUnsubscribe from "./hooks/useUnsubscribe";
import "./Reminders.css";

const UnsubscribeReminders = () => {
  const { reminderToken } = useParams<{ reminderToken: string }>();

  const [isChecked, setIsChecked] = useState(false);

  const { isSuccessful, isLoading, error, unsubscribeRequest } =
    useUnsubscribe();

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    unsubscribeRequest(reminderToken);
  };

  if (isSuccessful) {
    return (
      <p className="unsubscribe-response__successful">
        You have successfully unsubscribed from reminder emails.
      </p>
    );
  }

  if (error) {
    return (
      <p className="unsubscribe-response__error">
        Something went wrong. Please refresh the page and try again.
      </p>
    );
  }

  return (
    <>
      <form className="unsubscribe-form">
        <h1>Unsubscribe Email Reminders</h1>
        <p className="unsubscribe-form__description">
          If you wish to unsubscribe from all reminder emails, please check the
          box below.
        </p>
        <div className="unsubscribe-form__checkbox">
          <input
            id="unsubscribe-checkbox"
            type="checkbox"
            name="unsubscribe"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <label htmlFor="unsubscribe-checkbox">Unsubscribe</label>
        </div>
        <div className="unsubscribe-form__action">
          {isLoading ? (
            <LoaderSpinner />
          ) : (
            <button
              className={`unsubscribe-form__submit ${
                !isChecked ? "disabled" : ""
              }`}
              disabled={!isChecked}
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default UnsubscribeReminders;
