import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Step,
  debounce,
  Stepper,
  SelectChangeEvent,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { attachFormik } from "./formik";
import { Form } from "formik";
import type { FormikProps } from "formik";
import {
  TAppointmentReason,
  TClient,
  TAppointmentFormValues,
  TPracticeData,
} from "./types";
import { fillPetsArray } from "./helper";
import { lookupClient } from "./api/client";
import { ArrowBackIos } from "@mui/icons-material";
import { NO_PREFERRED_DOCTOR } from "./hooks/useSmartDateAPI";
import {
  isPhoneNumberValid,
  isEmailValid,
  isPhoneNumberEncrypted,
} from "../../utils/validation.util";
import useStripe from "../../components/Stripe/hooks/useStripe";
import ClientInfoStep from "./steps/ClientStep";
import AppointmentInfoStep from "./steps/AppointmentStep";
import CommentStep from "./steps/CommentStep";
import ReviewStep from "./steps/ReviewStep";
import SuccessStep from "./steps/SuccessStep";
import { getAppointmentReason } from "./api/appointment-reasons";
import CardStep from "./steps/CardStep";
import AdjustDateModal from "./steps/AdjustDateModal";
import useDateValidator from "./hooks/useDateValidator";

const stepFields = [
  // Step 1 - Client Information - Identification
  ["firstName", "lastName", "email", "phone", "appointmentReason"],
  // Step 2 - Appointment Details
  [
    "preferredDoctor",
    "petsCount",
    "preferredDate",
    "preferredTime",
    "firstAlternativeDate",
    "secondAlternativeDate",
    "pets",
    "species",
  ],
  // Step 3 - Confirm Details
  ["comment"],
  // Step 4 - Card details
  ["card"],
];

/**
 * Appointment Request Form
 * @returns Form component
 */
function AppointmentRequestForm(
  props: {
    apptRequestToken: string;
    practiceData: TPracticeData;
  } & FormikProps<TAppointmentFormValues>
) {
  const {
    apptRequestToken,
    values,
    touched,
    errors,
    status,
    isSubmitting,
    setFieldValue,
    resetForm,
    setTouched,
    setFieldError,
    handleChange,
    handleBlur,
    practiceData,
    validateForm,
    submitForm,
  } = props;

  const valuesRef = useRef(values);
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const { generatePaymentMethod, clearCardDetails, isCardInfoComplete } =
    useStripe();
  const { validateSelectedDate } = useDateValidator(apptRequestToken);

  const [isAdjustDateModalOpen, setIsAdjustDateModalOpen] = useState({
    isOpen: false,
    isSubmit: false,
  });

  const [clientLookup, setClientLookup] = useState<TClient | null>(null);
  const [appointmentReason, setAppointmentReason] =
    useState<TAppointmentReason | null>(null);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const intPetsCount = +(values.petsCount || "").replace("+", "");

  const isDirectBookingFlow = (appointmentReason: TAppointmentReason | null) => {
    if(!appointmentReason){
      return false
    }

    const isDirectBookingEnabledForNewClient = appointmentReason?.settings?.isDirectBookingEnabledForNewClient ?? null
    const isDirectBookingEnabledForExistingClient = appointmentReason?.settings?.isDirectBookingEnabledForExistingClient ?? null

    if(practiceData.isDirectBookingEnabled){
      if(isDirectBookingEnabledForNewClient && !clientLookup){
        return true
      }
      if (isDirectBookingEnabledForExistingClient && clientLookup?.isActive) {
        return true;
      }
    }
    return false;
  };

  const isCardStepRequired = (): boolean => {
    const isCardRequiredForNewClient =
      appointmentReason?.settings?.isCardRequiredForNewClient &&
      (!clientLookup || !clientLookup?.isActive);

    const isCardRequiredForExistingClient =
      appointmentReason?.settings?.isCardRequiredForExistingClient &&
      clientLookup &&
      clientLookup.isActive;

    return (
      (isDirectBookingFlow(appointmentReason) &&
        (isCardRequiredForNewClient || isCardRequiredForExistingClient)) ??
      false
    );
  };

  useEffect(() => {
    const validateDate = async () => {
       const currentValues = valuesRef.current;

      if (
        status !== undefined ||
        isAdjustDateModalOpen.isOpen ||
        !currentValues.isDirectBookingEnabled ||
        !currentValues.appointmentReasonId ||
        !currentValues.preferredTime ||
        !currentValues.duration ||
        !currentValues?.preferredDate
      ) {
        return;
      }
      const isDateStillValid = await validateSelectedDate(currentValues);
      if (!isDateStillValid) {
        setIsAdjustDateModalOpen({isOpen: true, isSubmit: false})
      }
    };

    const validateDatePolling = setInterval(validateDate, 60000);
    return () => clearInterval(validateDatePolling);
  }, [appointmentReason, isAdjustDateModalOpen, status]);

  useEffect(() => {
    const isDirectBookingEnabled = isDirectBookingFlow(appointmentReason);
    setFieldValue("isDirectBookingEnabled", isDirectBookingEnabled);
    setFieldValue(
      "type",
      isDirectBookingEnabled ? "DIRECT_BOOKING" : "APPOINTMENT_REQUEST"
    );

    if (appointmentReason) {
      setFieldValue("appointmentReason", appointmentReason.reason);
      setFieldValue("appointmentReasonId", appointmentReason.id);
      if (isDirectBookingEnabled) {
        setFieldValue("type", "DIRECT_BOOKING");
      } else {
        setFieldValue("type", "APPOINTMENT_REQUEST");
      }
      setFieldValue("duration", appointmentReason.duration);

      if (practiceData.isSmartAppointmentRequestsEnabled) {
        setFieldValue("preferredDoctor", NO_PREFERRED_DOCTOR);
      }
    }

    if (isDirectBookingEnabled) {
      setFieldValue("petsCount", "1");
    }
  }, [appointmentReason, clientLookup]);

  const handleAppointmentReasonSelectChange = async (event: SelectChangeEvent<string>) => {
    try {
      const selectedId: string = event.target.value;
      const selectedAppointmentReason: TAppointmentReason | null = await getAppointmentReason(apptRequestToken, selectedId);
      setAppointmentReason(selectedAppointmentReason);
    } catch (error) {
      console.error("Error in handling appointment reason selection:", error);
    }
  };

  const resetFormFields = () => {
    clearCardDetails();
    setAppointmentReason(null);
    setFieldValue("isNewClient", true);
    setFieldValue("isNewPatient", true);
    setFieldValue("isPhoneTextable", true);
    if (values.phone && isPhoneNumberEncrypted(values.phone)) {
      setFieldValue("phone", "");
    }
    setFieldValue("petsCount", values.isDirectBookingEnabled ? "1" : "");
    setFieldValue("pets", []);
    setFieldValue("practiceDoctorId", undefined);
    setFieldValue("clientId", undefined);
    setFieldValue("isDirectBookingEnabled", false);
    setFieldValue("type", "");
    setFieldValue("duration", "");
  };

  useEffect(() => {
    if (clientLookup) {
      setFieldValue("clientId", clientLookup.id);
      setFieldValue("isNewClient", false);
      setFieldValue("pets", fillPetsArray(1, values.pets));
      setFieldValue("petsCount", "1");
      setFieldValue("practiceDoctorId", undefined);
      if (
        !values.phone ||
        (values.phone && isPhoneNumberEncrypted(values.phone))
      ) {
        if (clientLookup.isActive && clientLookup.phoneNumberLastFour) {
          setFieldValue("phone", `***-***-${clientLookup.phoneNumberLastFour}`);
        } else {
          setFieldValue("phone", "");
        }
      }
    } else {
      resetFormFields();
    }
  }, [clientLookup]);

  const isValidStep = async (step: number) => {
    return validateForm().then((errors) => {
      if (errors && Object.keys(errors).length < 0) {
        setTouched({});
        return false;
      }
      const fieldSet = stepFields[step];
      const errObj: Record<string, string> = {};
      let valid = true;
      fieldSet.forEach((key) => {
        const err = (errors as Record<string, string>)[key];
        if ((typeof err === "string" && err) || (Array.isArray(err) && err.length)) {
          valid = false;
          errObj[key] = err;
        } else {
          setFieldError(key, "");
        }
      });

      if (valid) {
        setTouched({});
        return true;
      } else {
        setTouched(errObj);
        return false;
      }
    });
  };

  const CancelBtn = () => {
    if (activeStep < 1 && Object.keys(touched).length <= 0) return null;
    return (
      <Button
        variant="text"
        sx={{ textTransform: "none", color: "#1d1c2f", fontSize: "12px" }}
        onClick={() => {
          resetForm();
          setClientLookup(null);
          resetFormFields();
          handleReset();
        }}
      >
        Cancel
      </Button>
    );
  };

  const debounceClientLookup = useCallback(
    debounce(async (apptRequestToken:string, firstName:string, lastName:string, email?:string, phone?:string) => {
      // Check if the phone number is defined. valid and not encrypted before removing special characters
      const cleanedPhone = phone && isPhoneNumberValid(phone) && !isPhoneNumberEncrypted(phone) ? phone.replace(/\D/g, '') : undefined;
      const response = await lookupClient(apptRequestToken, firstName, lastName, email, cleanedPhone);

      if (response?.data?.clients?.length > 0) {
        // Sort clients so that active clients are first
        const sortedClients = response.data.clients.sort((a: TClient, b: TClient) => {
          return (b.isActive === a.isActive) ? 0 : a.isActive ? -1 : 1;
        });
        if (!clientLookup || clientLookup.id !== sortedClients[0]?.id) {
          setClientLookup(sortedClients[0]);
        }
      } else {
        setClientLookup(null);
      }
    }, 500),
    []
  );

  useEffect(() => {
    const isValidInput = () => {
      const isPhoneValid = values.phone?.length > 0 && isPhoneNumberValid(values.phone);
      const isEmailAddressValid = values.email?.length > 0 && isEmailValid(values.email);
    
      return isPhoneValid || isEmailAddressValid;
    };

    const performClientLookup = async () => {
      if (isValidInput()) {
        try {
          await debounceClientLookup(apptRequestToken, values.firstName, values.lastName, values.email, values.phone);
        } catch (error) {
          console.error("Error looking up client");
          setClientLookup(null); // Set clientLookup to null in case of an error
        }
      }
    };

    performClientLookup();
  }, [values.email, values.firstName, values.lastName, values.phone]);

  // This function injects the animalId value based on matching
  // pet names between the client's pets and the inserted pet names
  const injectAnimalData = () => {
    const insertedPetNames = values?.pets || [];
    setFieldValue("animalName", insertedPetNames[0].name);
    const clientPets = clientLookup?.animals || [];
    if (clientPets?.length > 0 && insertedPetNames.length > 0) {
      const animalFound = clientPets.find((clientPet) =>
        insertedPetNames.some(
          (insertedPetName) =>
            clientPet.name.toLowerCase() ===
            insertedPetName.name.trim().toLowerCase()
        )
      );

      if (animalFound) {
        setFieldValue("animalId", animalFound.id);
        setFieldValue("animalName", animalFound.name);
        setFieldValue("isNewPatient", false);
      }
    }
  };

  const injectCreditCardData = async () => {
    const billingDetails = {
      name: `${values.firstName || ""} ${values.lastName || ""}`,
    };

    const response = await generatePaymentMethod(billingDetails);

    if (!response) {
      return false;
    }

    const { card, billing_details } = response.paymentMethod || {};
    setFieldValue("paymentMethodId", response.paymentMethod?.id ?? null);
    setFieldValue("last4", card?.last4 ?? null);
    setFieldValue("cardType", card?.brand ?? null);
    setFieldValue("zipCode", billing_details?.address?.postal_code ?? null);
    setFieldValue("nameOnCard", billing_details?.name ?? null);

    return true;
  };

  const BackButton = () => {
    if (activeStep <= 0 || activeStep > 4) return null;
    return (
      <Button
        onClick={handleBack}
        variant="text"
        sx={{
          textTransform: "none",
          color: "#1d1c2f",
        }}
      >
        <ArrowBackIos sx={{ fontSize: "12px", mr: 2 }} /> Back
      </Button>
    );
  };

  const handleSubmitForm = async () => {
    if (isDirectBookingFlow(appointmentReason)) {
      const isDateStillValid = await validateSelectedDate(values);
      if (isDateStillValid === false) {
        setIsAdjustDateModalOpen({isOpen: true, isSubmit: true});
        return;
      }
    }

    if (isCardStepRequired()) {
      const success = await injectCreditCardData();
      if (!success) {
        return;
      }
    }

    injectAnimalData();

    setTimeout(() => {
      submitForm().then(() => setActiveStep(5));
    }, 0);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Form style={{ paddingTop: "30px" }}>
        <BackButton />
        <AdjustDateModal
          isOpen={isAdjustDateModalOpen.isOpen}
          isSubmit={isAdjustDateModalOpen.isSubmit}
          closeModal = { () => setIsAdjustDateModalOpen({isOpen: false, isSubmit: false})}
          practiceData={practiceData}
          appointmentReason={appointmentReason}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          apptRequestToken={apptRequestToken}
          onSubmit={() => {
            validateForm().then(async (errors) => {
              if (errors && Object.keys(errors).length > 0) return;
              setIsAdjustDateModalOpen({isOpen: false, isSubmit: false})
              handleSubmitForm();
            });
          }}
        />
        <Stepper
          activeStep={activeStep}
          orientation="horizontal"
          sx={{ flexDirection: "column" }}
        >
          <Step key={0} hidden={activeStep !== 0}>
            <ClientInfoStep
              appointmentReason={appointmentReason}
              practiceData={practiceData}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              handleAppointmentReasonSelectChange={
                handleAppointmentReasonSelectChange
              }
              clientLookup={clientLookup}
              isValidStep={isValidStep}
              handleNext={handleNext}
              CancelBtn={<CancelBtn />}
            />
          </Step>
          <Step key={1} hidden={activeStep !== 1}>
            <AppointmentInfoStep
              apptRequestToken={apptRequestToken}
              intPetsCount={intPetsCount}
              appointmentReason={appointmentReason}
              practiceData={practiceData}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              clientLookup={clientLookup}
              isValidStep={isValidStep}
              handleNext={handleNext}
              CancelBtn={<CancelBtn />}
            />
          </Step>
          <Step key={2} hidden={activeStep !== 2}>
            <CommentStep
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              isValidStep={isValidStep}
              handleNext={handleNext}
              CancelBtn={<CancelBtn />}
            />
          </Step>
          <Step key={3} hidden={activeStep !== 3}>
            <ReviewStep
              isCardStepRequired={isCardStepRequired}
              setActiveStep={setActiveStep}
              status={status}
              intPetsCount={intPetsCount}
              isSubmitting={isSubmitting}
              practiceData={practiceData}
              values={values}
              onSubmit={() => {
                validateForm().then(async (errors) => {
                  if (errors && Object.keys(errors).length > 0) return;

                  if (isCardStepRequired()) {
                    handleNext();
                  } else {
                    handleSubmitForm();
                  }
                });
              }}
            />
          </Step>
          <Step key={4} hidden={activeStep !== 4}>
            <CardStep
              isCardInfoComplete={isCardInfoComplete}
              handleSubmitFormDirectBooking={() => handleSubmitForm()}
              status={status}
              isSubmitting={isSubmitting}
              appointmentReason={appointmentReason}
              practiceName={practiceData?.practiceName}
            />
          </Step>
          <Step key={5} hidden={activeStep !== 5}>
            <SuccessStep practiceData={practiceData} values={values} />
          </Step>
        </Stepper>
      </Form>
    </LocalizationProvider>
  );
}

const AppointmentForm = attachFormik(AppointmentRequestForm);

export default AppointmentForm;
