import { validateAppointmentRequest } from "../api/appointment";
import { TAppointmentFormValues, TValidateAppointmentPayload } from "../types";

const useDateValidator = (apptRequestToken: string) => {
  const validateSelectedDate = async (values: TAppointmentFormValues) => {
    let isAvailable = false;
    try {
      const payload: TValidateAppointmentPayload = {
        appointmentReasonId: values.appointmentReasonId,
        preferredTime: values.preferredTime,
        duration: values.duration,
        preferredDate:
          values?.preferredDate?.format("YYYY-MM-DD") + "T00:00:00Z",
      };

      if (values.practiceDoctorId) {
        payload.practiceDoctorId = values.practiceDoctorId;
      }

      const result: any = await validateAppointmentRequest(
        apptRequestToken,
        payload
      );

      isAvailable = result?.success === true;
    } catch (error: any) {
      isAvailable = false;
    }

    return isAvailable;
  };

  return {
    validateSelectedDate,
  };
};

export default useDateValidator;
