import React from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { TAppointmentFormValues, TPracticeData } from "../types";
import { NO_PREFERRED_DOCTOR } from "../hooks/useSmartDateAPI";
import { FormikProps } from "formik";

type TReviewStepProps = {
  isCardStepRequired: () => boolean;
  setActiveStep: (step: number) => void;
  intPetsCount: number;
  practiceData: TPracticeData;
  onSubmit: () => void;
} & Pick<
  FormikProps<TAppointmentFormValues>,
  "isSubmitting" | "values" | "status"
>;

const ReviewStep: React.FC<TReviewStepProps> = ({
  isCardStepRequired,
  onSubmit,
  status,
  intPetsCount,
  isSubmitting,
  practiceData,
  values,
}) => {
  const showError = () => {
    const bookingType = values.isDirectBookingEnabled ? "booked" : "requested";
    const errorMessage = `There was some problem submitting the form. Your appointment cannot be ${bookingType} online at this time.`;

    return (
      <Grid item xs={12} mb={1} mt={3}>
        <Grid item xs={12} justifyItems={"center"} ml="auto" mr="auto">
          <Alert severity="error">{errorMessage}</Alert>
        </Grid>
      </Grid>
    );
  };

  const renderSelectedPreferredDoctor = () => {
    if (
      practiceData.isSmartAppointmentRequestsEnabled &&
      values.preferredDoctor !== NO_PREFERRED_DOCTOR
    ) {
      return practiceData.doctorList.find(
        (doctor) => doctor.id === values.preferredDoctor
      )?.displayName;
    }
    return values.preferredDoctor;
  };

  const renderButtonLabel = () => {
    if (isCardStepRequired()) {
      return "Next";
    }
    if (values.isDirectBookingEnabled) {
      return "Book Appointment";
    }
    return "Send Request";
  };

  const renderSubTitle = () => {
    if (isCardStepRequired()) {
      return null;
    }
    if (values.isDirectBookingEnabled) {
      return (
        <Typography variant="body2" mt={3}>
          Clicking <strong>Book Appointment</strong> will instantly schedule
          your appointment.
        </Typography>
      );
    }
    return (
      <Typography variant="body2" mt={3}>
        Click <strong>Send Request</strong> to submit your appointment request.
        The appointment is not confirmed until the practice contacts you.
      </Typography>
    );
  };

  return (
    <>
      <Box maxWidth={430}>
        <Typography variant="h2" mt={2}>
          Take a moment to check your appointment information
        </Typography>
        {renderSubTitle()}
      </Box>

      <Box fontSize={15} mt={4}>
        <strong>Contact information:</strong>
        <br />
        <br />
        <table
          className="table-raw-compact"
          cellPadding={0}
          cellSpacing={0}
          border={0}
        >
          <tbody>
            <tr>
              <td width={150}>Name:</td>
              <td>
                {values.firstName} {values.lastName}
              </td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{values.email}</td>
            </tr>
            <tr>
              <td>Telephone:</td>
              <td>{values.phone}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        {values.pets.length > 0 && (
          <>
            <strong>Appointment information:</strong>
            <br />
            <br />
            <table
              className="table-raw-compact"
              cellPadding={0}
              cellSpacing={0}
              border={0}
            >
              <tbody>
                {values.pets.map((pet, i) => (
                  <tr key={i}>
                    <td width={150}>
                      Pet {intPetsCount > 1 ? i + 1 : ""} Name:
                    </td>
                    <td>{pet.name}</td>
                  </tr>
                ))}

                <tr>
                  <td>Reason for Visit:</td>
                  <td>{values.appointmentReason}</td>
                </tr>
              </tbody>
            </table>

            <br />
            <br />
          </>
        )}

        {!values.isDirectBookingEnabled && (
          <>
            <strong>Date &amp; Time availability:</strong>
            <br />
            <br />
          </>
        )}

        <table
          className="table-raw-compact"
          cellPadding={0}
          cellSpacing={0}
          border={0}
        >
          <tbody>
            <tr>
              <td width={150}>
                <strong>
                  {values.isDirectBookingEnabled
                    ? "Date & Time:"
                    : "Preferred:"}
                </strong>
              </td>
              <td>
                {dayjs(values.preferredDate).format("MM/DD/YYYY")} at{" "}
                {values.preferredTime}
              </td>
            </tr>
            {values.firstAlternativeDate && (
              <tr>
                <td>
                  <strong>First Alternative:</strong>
                </td>
                <td>
                  {dayjs(values.firstAlternativeDate).format("MM/DD/YYYY")}
                </td>
              </tr>
            )}
            {values.secondAlternativeDate && (
              <tr>
                <td>
                  <strong>Second Alternative:</strong>
                </td>
                <td>
                  {dayjs(values.secondAlternativeDate).format("MM/DD/YYYY")}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <br />
        <br />

        <table
          className="table-raw-compact"
          cellPadding={0}
          cellSpacing={0}
          border={0}
        >
          <tbody>
            {values.preferredDoctor && (
              <tr>
                <td width={150}>
                  <strong>
                    {values.isDirectBookingEnabled ? "Preferred" : ""} Doctor:
                  </strong>
                </td>
                <td>{renderSelectedPreferredDoctor()}</td>
              </tr>
            )}
            <tr>
              <td width={150} valign="top">
                <strong>Comment: </strong>
              </td>
              <td
                style={{
                  whiteSpace: "pre-line",
                  wordBreak: "break-word",
                }}
              >
                {values.comment || "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      {status?.data &&
        status.success === false &&
        !isCardStepRequired() &&
        showError()}

      <Grid container spacing={"18px"} mt={2}>
        <Grid item xs={12} mb={1}>
          <Button
            style={{
              width: "220px",
              background: "#1d1c2f",
            }}
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {isSubmitting ? (
              <CircularProgress sx={{ color: "#fff" }} size={24} />
            ) : (
              renderButtonLabel()
            )}
          </Button>
        </Grid>
        <Grid item xs={12} mb={1} pb={4}>
          <Typography fontSize={9} maxWidth={320} lineHeight={1.4}>
            {`By continuing, you acknowledge that ${practiceData?.practiceName} is
              using Scratch Financial, Inc. (“Scratch Pay”) to book your
              appointment. You also confirm that you have read and agreed to
              Scratch Pay's `}
            <a
              target="_blank"
              href="https://scratchpay.com/legal/privacy"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            {` and ${practiceData?.practiceName}'s Privacy Policy. You further
              agree to receive appointment reminders and related information,
              including marketing information, from ${practiceData?.practiceName} 
              via phone, text, or email. Message and data rates may apply.`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewStep;
