import React from "react";
import { Box, FormHelperText, Grid, Rating, Typography } from "@mui/material";
import { TFeedbackFormData } from "./types";
import { Label } from "@mui/icons-material";

export default function FeedbackStarRating({
  data,
  setRating,
  formErrors,
}: {
  data: TFeedbackFormData | null;
  setRating: (val: number) => void;
  formErrors: any;
}) {
  return (
    <Box mb={4} maxWidth={'300px'}>
      {formErrors.rating && <FormHelperText error>{formErrors.rating}</FormHelperText>}
      <Rating
        name="simple-controlled"
        value={data?.rating || 0}
        max={10}
        size="large"
        onChange={(event, newValue) => {
          setRating(Number(newValue));
        }}
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      />

      <Grid container justifyContent={"space-between"} sx={{lineHeight: '12px'}}>
        <Grid item>
          <Typography component="legend" fontSize={10}>
            Very
            <br />
            Unlikely
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="legend" fontSize={10}>
            Unlikely
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="legend" fontSize={10}>
            Likely
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="legend" fontSize={10}>
            Very
            <br />
            Likely
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
