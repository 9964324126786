import { useState } from "react";
import {
  fetchAppointmentForm as fetchAppointmentFormRequest,
  postAppointmentForm,
} from "../api/appointmentForm";
import { AppointmentConfirmationPayload, TAppointmentInfo } from "../types";

type IUseAppointmentForm = {
  appointmentForm: TAppointmentInfo | undefined;
  isLoading: boolean;
  isSuccessful: boolean;
  error: boolean;
  isSaving: boolean;
  isCatOnly: boolean;
  fetchAppointmentForm: (emailToken: string | undefined) => Promise<void>;
  sendAppointmentForm: (
    formId: string,
    payload: AppointmentConfirmationPayload
  ) => Promise<void>;
};

const useAppointmentForm = (): IUseAppointmentForm => {
  const [appointmentForm, setAppointmentForm] = useState<TAppointmentInfo>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isCatOnly, setIsCatOnly] = useState<boolean>(false);

  const fetchAppointmentForm = async (emailToken: string | undefined) => {
    if (!emailToken) {
      setError(true);
      return;
    }

    setLoading(true);
    try {
      const response = await fetchAppointmentFormRequest(emailToken);
      setAppointmentForm(response.data);
      setIsSuccessful(true);
    } catch (err: unknown) {
      setError(true);
    }
    setLoading(false);
  };

  const sendAppointmentForm = async (
    formId: string,
    payload: AppointmentConfirmationPayload
  ) => {
    if (!formId) {
      return;
    }

    setIsSaving(true);
    try {
      const response = await postAppointmentForm(formId, payload);
      setIsSuccessful(true);
      setIsCatOnly(response?.data?.isCatOnlyPractice);
    } catch (err: unknown) {
      setError(true);
    }
    setIsSaving(false);
  };

  return {
    appointmentForm,
    isSuccessful,
    isLoading,
    error,
    isSaving,
    isCatOnly,
    fetchAppointmentForm,
    sendAppointmentForm,
  };
};

export default useAppointmentForm;
