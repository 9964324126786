import React from "react";
import { Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { TAppointmentFormValues } from "../types";

type TCommentStepProps = {
  CancelBtn: React.ReactNode;
  handleNext: () => void;
  isValidStep: (arg: number) => Promise<boolean>;
} & Pick<
  FormikProps<TAppointmentFormValues>,
  "values" | "touched" | "errors" | "handleChange" | "handleBlur"
>;

const CommentStep: React.FC<TCommentStepProps> = ({
  CancelBtn,
  handleNext,
  isValidStep,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
}) => {
  return (
    <>
      <Typography fontSize={24} mt={2}>
        Add any additional appointment details below:
      </Typography>
      <Typography fontSize={13} mt={2}>
      Please let us know if your pet has ever required sedation for treatment or experienced any vaccine reactions.
      </Typography>
      <Grid container spacing={"18px"} mt={2}>
        <Grid item xs={12} mb={1}>
          <InputLabel shrink>Comment</InputLabel>
          <TextField
            multiline
            fullWidth
            name={"comment"}
            value={values.comment}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.comment ? errors.comment : ""}
            error={touched.comment && Boolean(errors.comment)}
            rows={4}
          />
          <InputLabel shrink sx={{ mt: 1 }}>
            250 characters max
          </InputLabel>
        </Grid>
        <Grid item xs={12} mb={1} textAlign={"center"}>
          <Button
            onClick={() => {
              isValidStep(2).then((res) => {
                if (res === true) handleNext();
              });
            }}
            variant="contained"
            color="primary"
            sx={{ width: "130px" }}
          >
            Next
          </Button>

          <br />
          <br />
          {CancelBtn}
        </Grid>
      </Grid>
    </>
  );
};

export default CommentStep;
