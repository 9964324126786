import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfirmAppointment } from "../features/appointments";
import { UnsubscribeReminders } from "../features/reminders";
import AppointmentWidgetV2 from "../features/appointmentWidgetV2/AppointmentWidget";
import { FeedbackPage } from "../features/feedback";
import PreAppointmentForm from "../features/preAppointmentForm/PreAppointmentForm";

 const Routes = () => {
  const publicRoutes = createBrowserRouter([
    {
      path: "/",
      element: <>Scratchpay</>,
      errorElement: <>Page not found</>,
    },
    {
      path: "confirm-appointment/:emailToken",
      element: <ConfirmAppointment />,
    },
    {
      path: ":practiceSlug/unsubscribe/:reminderToken",
      element: <UnsubscribeReminders />,
    },
    {
      path: "appointment/book/:apptRequestToken",
      element: <AppointmentWidgetV2 />,
    },
    {
      path: "feedback/:feedbackToken",
      element: <FeedbackPage />,
    },
    {
      path: "appointment-form/:emailToken",
      element: <PreAppointmentForm />,
    },
  ]);

  return <RouterProvider router={publicRoutes} />;
};

export default Routes;