import React from "react";
import { Box, Button, Grid, Rating, TextField, Typography } from "@mui/material";
import FeedbackStarRating from "./FeedbackStarRating";
import { TFeedbackFormData } from "./types";
import { getGooglePlaceReviewUrl } from "./util";
import { CloseTextButton } from "../../components/Buttons/CloseTextButton";

function MessageHighRating({ onReviewButtonClick }: { onReviewButtonClick: () => void }) {
  return (
    <>
      <Rating readOnly value={3} max={3} size="medium" sx={{ my: 4 }} />
      <Typography fontSize={"19px"} lineHeight={"24px"} mb={3}>
        Share your experience with others by leaving us a review!
      </Typography>
      <p>
        <Button onClick={onReviewButtonClick} size="large" variant="contained" sx={{ width: "145px" }}>
          Write Review
        </Button>
      </p>
    </>
  );
}

function MessageLowRating({
  onReviewButtonClick,
  practicePhone,
}: {
  onReviewButtonClick: () => void;
  practicePhone: string;
}) {
  return (
    <>
      <Typography fontSize={"19px"} lineHeight={"24px"} mb={3} mt={3}>
        To share more, please call our practice at {practicePhone}.
      </Typography>
      <p>
        <Button
          onClick={onReviewButtonClick}
          size="large"
          variant="text"
          sx={{
            fontWeight: 400,
            textDecoration: "underline",
            textTransform: "unset",
            fontSize: 16,
            color: '#5B70FF'
          }}
        >
          Visit us on Google
        </Button>
      </p>
    </>
  );
}

export default function FeedbackSuccess({ data, feedbackResponse }: { data: TFeedbackFormData | null, feedbackResponse: any|null }) {
  if (!data) return null;

  const onReviewButtonClick = () => {
    if(!feedbackResponse) return;
    window.open(getGooglePlaceReviewUrl(feedbackResponse?.googlePlaceId), "_blank");
  };

  return (
    <Grid container gap={"75px"} justifyContent={"center"} direction={{ xs: "row" }} ml={"auto"} mr={"auto"}>
      <Grid item maxWidth={"300px"} pt={4} textAlign={"center"}>
        <Box>
          <img
            width={"206px"}
            src={
              "https://6598889.fs1.hubspotusercontent-na1.net/hubfs/6598889/Email templates/Vetcor/Reminder-Confirmation/art-feedback-after-visit-pets.png"
            }
            alt=""
          />
        </Box>
        <Typography variant={"h2"} mt={2} mb={4} fontSize={"30px"} lineHeight={"38px"}>
          Your feedback has been sent to our practice.
        </Typography>
        <Typography fontSize={"19px"} lineHeight={"24px"} mb={0}>
          Thank you for completing the survey. Your input will improve our services for all patients.
        </Typography>

        
        {data.rating <= 7  && <MessageLowRating onReviewButtonClick={onReviewButtonClick} practicePhone={"Hello"} />}
        {data.rating >= 8 && <MessageHighRating onReviewButtonClick={onReviewButtonClick} />}
        
        <br />
        <p>
          <CloseTextButton onClick={window.close}></CloseTextButton>
        </p>
      </Grid>
    </Grid>
  );
}
