import axiosClient from "../../../config/axios";
import { TAppointmentReason } from "../types";


export async function getAppointmentReason(
  practiceToken: string,
  appointmentReasonId: string,
): Promise<TAppointmentReason | null> { 
  try {
    const response = await axiosClient.get(`/api/practices/${practiceToken}/appointment-reasons/${appointmentReasonId}`);
    return response?.data?.appointmentReason;
  } catch (error) {
    console.error("Error fetching appointment reason:", error);
    return null;
  }
}

