import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { CardElement } from "@stripe/react-stripe-js";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
const backgroundColor = "#f7f8ff";
const textColor = "#5b70ff";

const CardElementContainer = styled("div")(() => ({
  height: "40px",
  backgroundColor: backgroundColor,
  display: "flex",
  alignItems: "center",
  "& .StripeElement": {
    width: "100%",
    padding: "40px",
  },
}));

export const StripeCardElement = () => {

  const iframeStyles = useMemo(
    () => ({
      base: {
        color: textColor,
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: textColor,
        },
      },
      invalid: {
        color: "#FF595A",
        iconColor: "#FF595A",
      },
      complete: {
        iconColor: "#60EDCE",
      },
    }),
    []
  );

  const cardStyle: StripeCardNumberElementOptions = useMemo(
    () => ({
      style: iframeStyles,
      hidePostalCode: false,
      iconStyle: "solid",
    }),
    [iframeStyles]
  );

  return (
    <Box
      sx={{
        width: "100%",
        margin: "30px auto",
        paddingY: "10px",
        borderRadius: "10px",
        backgroundColor: backgroundColor,
      }}
    >
      <Typography
        variant="subtitle2"
        color={textColor}
        align="left"
        sx={{
          ml: 2,
          pb: 1,
        }}
      >
        Enter Card Information
      </Typography>
      <CardElementContainer>
        <CardElement options={cardStyle} />
      </CardElementContainer>
    </Box>
  );
};

StripeCardElement.propTypes = {
  style: PropTypes.object,
};
