import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useConfirm from "./hooks/useConfirm";
import LoaderSpinner from "../../components/LoaderSpinner/LoaderSpinner";
import "./Appointment.css";
import { Button, ThemeProvider, Typography } from "@mui/material";
import useAppointmentForm from "../preAppointmentForm/hooks/useAppointmentForm";
import { theme } from "./theme";
import { CloseTextButton } from "../../components/Buttons/CloseTextButton";

const ConfirmAppointment = () => {
  const { emailToken } = useParams<{ emailToken: string }>();

  const [showPreAppointmentForm, setShowPreAppointmentForm] = useState(false);

  const { isSuccessful, isLoading, error, errorMessage, confirmAppointment } = useConfirm();
  const { appointmentForm, fetchAppointmentForm } = useAppointmentForm();

  useEffect(() => {
    confirmAppointment(emailToken);
    fetchAppointmentForm(emailToken);
  }, []);

  useEffect(() => {
    if (!appointmentForm) return;
    if (!Array.isArray(appointmentForm.appointmentFormList)) return;
    setShowPreAppointmentForm(appointmentForm.appointmentFormList.length > 0);
  }, [appointmentForm]);

  const renderContent = () => {
    if (isLoading) {
      return <LoaderSpinner />;
    }

    if (error) {
      return (
        <>
          <figure className="confirm-appointment__figure-container-error">
            <img
              className="confirm-appointment__figure"
              src="https://6598889.fs1.hubspotusercontent-na1.net/hubfs/6598889/Email templates/Vetcor/Reminder-Confirmation/vetcor-appt-confirm-error@2x.png"
              alt="Calendar"
            />
          </figure>
          <Typography variant="h1" className="confirm-appointment__error_title">Oops!</Typography>
          <Typography className="confirm-appointment__subtitle">Something went wrong.</Typography>
          <Typography className="confirm-appointment__subtitle">{errorMessage}</Typography>
        </>
      );
    }

    if (isSuccessful) {
      return (
        <>
          <figure className="confirm-appointment__figure-container">
            <img
              className="confirm-appointment__figure"
              src="https://getscratch.com/hubfs/getscratch/images/graphics/vetcor-appt-confirm-asset.svg"
              alt="Calendar"
            />
          </figure>
          <h1 className="confirm-appointment__title">Thank you!</h1>
          <Typography mb={4} className="confirm-appointment__subtitle">Your appointment has been confirmed.</Typography>
          
          {showPreAppointmentForm && (
            <>
              <Typography className="confirm-appointment__subtitle" textAlign={"center"}>
                Kindly complete your pre-appointment
                <br />
                forms prior to your visit by clicking below.
              </Typography>
              <Typography mt={4}>
                <Link to={`/appointment-form/${emailToken}`}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Complete Forms
                  </Button>
                </Link>
              </Typography>
            </>
          )}
          <Typography mt={3}>
            <CloseTextButton onClick={window.close}></CloseTextButton>
          </Typography>
        </>
      );
    }

    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="confirm-appointment__section">
        <div className="confirm-appointment__container">{renderContent()}</div>
      </div>
    </ThemeProvider>
  );
};

export default ConfirmAppointment;
