import React from "react";
import dayjs from "dayjs";
import { FormHelperText, InputLabel } from "@mui/material";
import { FormikProps } from "formik";
import { TAppointmentFormValues, TPracticeData } from "../types";
import { TBusinessHours } from "../steps/AppointmentStep";
import { weekDays } from "../../../utils/date.util";
import HourSelect from "../../../components/HourSelect";

type HourPickerProps = {
  practiceData: TPracticeData;
} & Pick<
  FormikProps<TAppointmentFormValues>,
  "values" | "handleChange" | "handleBlur" | "errors" | "touched"
>;

const HourPicker = ({
  practiceData,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}: HourPickerProps) => {
  const businessHours: TBusinessHours = new Map();

  practiceData.practiceHoursOfOperation.forEach((opday) => {
    businessHours.set(opday.day.toLowerCase(), { ...opday });
  });

  const selectedPreferredDay = businessHours.get(
    weekDays[dayjs(values.preferredDate).get("d")]
  );

  return (
    <>
      <InputLabel shrink>
        {values.isDirectBookingEnabled ? "Pick" : "Preferred"} time
      </InputLabel>
      <HourSelect
        value={values.preferredTime || ""}
        startTime={selectedPreferredDay?.start}
        endTime={selectedPreferredDay?.close}
        selectProps={{
          fullWidth: true,
          name: "preferredTime",
          onChange: handleChange,
          onBlur: handleBlur,
          error: touched.preferredTime && Boolean(errors.preferredTime),
        }}
      />
      {touched.preferredTime && errors.preferredTime && (
        <FormHelperText error>{errors.preferredTime}</FormHelperText>
      )}
    </>
  );
};

export default HourPicker;
