import React, { useEffect } from "react";

const debouncedParentPostMessage = ((delay: number) => {
  let timeoutId: null | ReturnType<typeof setTimeout> = null;

  return (data: Record<string, any>, target: string) => {
    timeoutId && clearTimeout(timeoutId);
    // Reset the throttle after a certain delay (e.g., 500 milliseconds)
    timeoutId = setTimeout(() => {
      // Message parent window
      window.parent.postMessage(data, target);
    }, delay);
  };
})(500);

const ContentObserver: React.FC<{ eventName: string }> = ({ eventName }) => {
  useEffect(() => {
    const observer = new MutationObserver(function () {
      debouncedParentPostMessage({ type: eventName, height: document.documentElement.offsetHeight }, "*");
    });

    observer.observe(document.body, { attributes: false, childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};

export default ContentObserver;
