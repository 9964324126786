import React from "react";
import DynamicCheckbox from "./DynamicElements/DynamicCheckbox";
import DynamicInput from "./DynamicElements/DynamicInput";
import DynamicSelect from "./DynamicElements/DynamicSelect";
import DynamicRadioGroup from "./DynamicElements/DynamicRadioGroup";
import { IDynamicFormProps } from "./DynamicElements/types";

interface DynamicFormProps extends IDynamicFormProps {
  isActiveStepCompleted: boolean;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  handleChange,
  isActiveStepCompleted,
  fieldType,
  fieldId,
  fieldLabel,
  fieldPlaceholder,
  fieldValue,
  fieldHelperText,
  fieldOptions,
  fieldMandatory,
}) => {
  switch (fieldType) {
    case "text":
      return (
        <DynamicInput
          fieldId={fieldId}
          fieldLabel={fieldLabel}
          fieldPlaceholder={fieldPlaceholder}
          fieldValue={fieldValue as string}
          fieldHelperText={fieldHelperText}
          fieldMandatory={fieldMandatory}
          fieldDisabled={isActiveStepCompleted}
          handleChange={handleChange}
        />
      );
    case "select":
      return (
        <DynamicSelect
          fieldId={fieldId}
          fieldLabel={fieldLabel}
          fieldValue={fieldValue as string}
          fieldHelperText={fieldHelperText}
          fieldOptions={fieldOptions}
          fieldMandatory={fieldMandatory}
          fieldDisabled={isActiveStepCompleted}
          handleChange={handleChange}
        />
      );
    case "multiple_choice":
      return (
        <DynamicRadioGroup
          fieldId={fieldId}
          fieldLabel={fieldLabel}
          fieldOptions={fieldOptions}
          fieldValue={fieldValue as string}
          fieldHelperText={fieldHelperText}
          fieldMandatory={fieldMandatory}
          fieldDisabled={isActiveStepCompleted}
          handleChange={handleChange}
        />
      );
    case "checkbox":
      return (
        <DynamicCheckbox
          fieldId={fieldId}
          fieldLabel={fieldLabel}
          fieldOptions={fieldOptions}
          fieldValue={fieldValue as string[]}
          fieldHelperText={fieldHelperText}
          fieldMandatory={fieldMandatory}
          fieldDisabled={isActiveStepCompleted}
          handleChange={handleChange}
        />
      );
    default:
      return null;
  }
};

export default DynamicForm;
