import React from "react";
import dayjs from "dayjs";
import { InputLabel } from "@mui/material";
import { FormikErrors } from "formik";
import { TPracticeData } from "../types";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { getNextWorkingDay, isDateAvailable } from "../helper";
import { TBusinessHours } from "../steps/AppointmentStep";

type DatePickerProps = {
  label: string;
  fieldName: string;
  practiceData: TPracticeData;
  value: any;
  touched?: boolean;
  error?: FormikErrors<Date> | undefined;
};

const DatePicker = ({
  label,
  fieldName,
  practiceData,
  value,
  touched,
  error,
}: DatePickerProps) => {
  const holidaySet = new Set<string>(practiceData.practiceDatesClosed);
  const businessHours: TBusinessHours = new Map();

  practiceData.practiceHoursOfOperation.forEach((opday) => {
    businessHours.set(opday.day.toLowerCase(), { ...opday });
  });

  const shouldDisableDate = (date: Date) => {
    const nextWorkingDate = getNextWorkingDay(4, businessHours, holidaySet);
    return !isDateAvailable(
      dayjs(date).toDate(),
      businessHours,
      holidaySet,
      nextWorkingDate
    );
  };

  return (
    <>
      <InputLabel shrink>{label}</InputLabel>
      <CustomDatePicker
        fieldName={fieldName}
        value={value}
        touched={touched}
        error={error}
        datePickerProps={{
          disablePast: true,
          shouldDisableDate,
        }}
      />
    </>
  );
};

export default DatePicker;
