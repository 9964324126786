import React, { useEffect, useState } from "react";
import { Box, Container, ThemeProvider, Typography, CircularProgress, Button } from "@mui/material";
import useAppointmentForm from "./hooks/useAppointmentForm";
import { useParams } from "react-router-dom";
import StepperReal, { StepperPropsChildren } from "./FormStepper";
import FormBuilder from "./FormBuilder";
import { theme } from "./form.theme";
import { CloseTextButton } from "../../components/Buttons/CloseTextButton";
import {
  AppointmentConfirmationPayload,
  AppointmentFormResponses,
  TAppointmentForm,
} from "./types";

function LoadingScreen() {
  return <CircularProgress size={32} sx={{ marginTop: 10 }} />;
}

function ErrorScreen() {
  return <Typography sx={{ marginTop: 10 }}>We couldn&apos;t locate the appointment form.</Typography>;
}

function getImageUrl(isCatOnly = false) {
  if (isCatOnly)
    return "https://6598889.fs1.hubspotusercontent-na1.net/hubfs/6598889/vetcor/art-pre-appt-form-success@2x-1.png";
  return "https://6598889.fs1.hubspotusercontent-na1.net/hubfs/6598889/vetcor/art-pre-appt-form-success@2x.png";
}
interface SuccessScreenProps {
  isCatOnly: boolean,
  showPreAppointmentForm: boolean
}
function SuccessScreen({ isCatOnly, showPreAppointmentForm }: SuccessScreenProps) {
  return (
    <>
      <img className="confirm-appointment__figure" src={getImageUrl(isCatOnly)} alt="" />
      <Typography variant="h1" fontSize={42} fontWeight={900} mb={3} mt={0}>
        Thank you!
      </Typography>
      <Typography textAlign={"center"} fontSize={21} maxWidth={400}>
        Your pre-appointment form has been sent to the practice.
      </Typography>

      {showPreAppointmentForm && (
        <>
          <Typography textAlign={"center"} fontSize={21} maxWidth={400} mt={5}>
          To complete a form for an additional pet,
            <br />
            please click the button below.
          </Typography>
          <Typography mt={4}>
            <Button size="large" variant="contained" color="primary" onClick={() => window.location.reload()}>
              Complete Form
            </Button>
          </Typography>
        </>
      )}

      <Typography mt={5}>
        <CloseTextButton onClick={window.close}></CloseTextButton>
      </Typography>
    </>
  );
}

const PreAppointmentForm = () => {
  const { emailToken } = useParams<{ emailToken: string }>();

  const [steps, setSteps] = useState<string[]>([]);
  const [isOnePerClient, setIsOnePerClient] = useState<boolean>(false);

  const {
    appointmentForm,
    isLoading,
    error,
    isSaving,
    isCatOnly,
    fetchAppointmentForm,
    sendAppointmentForm,
  } = useAppointmentForm();

  const onSendClick = async (
    formId: string,
    animalAppointmentConfirmationId: string,
    formValues: AppointmentFormResponses[]
  ): Promise<void> => {
    let animalAppointmentConfirmationIds: string[] = [
      animalAppointmentConfirmationId,
    ];

    if (
      appointmentForm?.appointmentFormList &&
      appointmentForm?.appointmentFormList?.length > 1 &&
      areAllIdsSame(appointmentForm?.appointmentFormList)
    ) {
      animalAppointmentConfirmationIds =
        appointmentForm?.appointmentFormList.map(
          (item: TAppointmentForm) => item.animalAppointmentConfirmationId
        );
    }

    const payload: AppointmentConfirmationPayload = {
      emailToken: emailToken as string,
      animalAppointmentConfirmationIds,
      appointmentFormResponses: formValues,
    };
    await sendAppointmentForm(formId, payload);
  };

  useEffect(() => {
    fetchAppointmentForm(emailToken);
  }, []);

  useEffect(() => {
    if (appointmentForm) {
      const sameFormForAllPets =
        appointmentForm?.appointmentFormList?.length > 1 &&
        areAllIdsSame(appointmentForm?.appointmentFormList);

      setIsOnePerClient(sameFormForAllPets);

      if (sameFormForAllPets) {
        setSteps(["All"]);
      } else {
        const petNames = appointmentForm?.appointmentFormList?.map(
          (pet: { petName: string }) => pet.petName
        );
        setSteps(petNames || []);
      }
    }
  }, [appointmentForm]);

  function areAllIdsSame(appointmentFormList: TAppointmentForm[]) {
    const firstId = appointmentFormList[0].id;
    return appointmentFormList.every(
      (item: TAppointmentForm) => item.id === firstId
    );
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingScreen />;
    } else if (error || steps.length === 0) {
      return <ErrorScreen />;
    } else {
      return (
        <StepperReal steps={steps} showStepper={!isOnePerClient}>
          {({
            activeStep,
            isActiveStepCompleted,
            handleCompleteStep,
            allStepsCompleted,
          }: StepperPropsChildren) => (
            <>
              {allStepsCompleted() ? (
                <SuccessScreen isCatOnly={isCatOnly} showPreAppointmentForm={steps.length > 0} />
              ) : (
                <>
                  {appointmentForm?.appointmentFormList[activeStep] && (
                    <FormBuilder
                      key={activeStep}
                      appointmentForm={
                        appointmentForm?.appointmentFormList[activeStep]
                      }
                      practiceData={appointmentForm}
                      isActiveStepCompleted={isActiveStepCompleted}
                      isSaving={isSaving}
                      handleCompleteStep={handleCompleteStep}
                      onSendClick={onSendClick}
                    />
                  )}
                </>
              )}
            </>
          )}
        </StepperReal>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"} sx={{ marginTop: 2 }}>
        <Box
          sx={{
            pt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {renderContent()}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PreAppointmentForm;
