import React, { useEffect, useState } from "react";
import { CircularProgress, Container, Grid, ThemeProvider, Typography } from "@mui/material";
import AppointmentRequest from "./AppointmentForm";
import { theme } from "./widget.theme";
import { useParams } from "react-router-dom";
import { getPracticeDetails } from "./api/appointment";
import { TPracticeData } from "./types";
import ContentObserver from "../../components/ContentObserver";
import "./style.scss";
import StripeWrapper from "../../components/Stripe/StripeWrapper";

function ErrorScreen() {
  return (
    <Typography align="center" fontSize={14} padding={2}>
      There was an error fetching practice details.
      <br />
      Please refresh the page to try again.
    </Typography>
  );
}

function LoadingScreen() {
  return (
    <Typography align="center" padding={2}>
      <CircularProgress />
    </Typography>
  );
}

function getPageTitle() {
  const url = new URL(location.href);
  const params = new URLSearchParams(url.search);
  if (!params.has("title")) null;
  const title = params.get("title") || "";
  if (title === "false" || title === "0") return null;
  return title;
}

export default function AppointmentWidget() {
  const [screen, setScreen] = useState("loading");
  const [practiceData, setPracticeData] = useState<TPracticeData>();
  const { apptRequestToken } = useParams<{ apptRequestToken: string }>();

  useEffect(() => {
    if (!apptRequestToken) return;
    getPracticeDetails(apptRequestToken)
      .then((data) => {
        setScreen("form");
        setPracticeData(data);
      })
      .catch(() => {
        setScreen("error");
      });
  }, []);

  const pageTitle = getPageTitle();

  return (
    <StripeWrapper>
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          {screen === "loading" && <LoadingScreen />}
          {screen === "error" && <ErrorScreen />}
          {screen === "form" && practiceData && apptRequestToken && (
            <>
              {pageTitle && <Typography variant="h2" mt={3} mb={6}>{pageTitle}</Typography>}
              <AppointmentRequest apptRequestToken={apptRequestToken} practiceData={practiceData} />
            </>
          )}
        </Container>
        <ContentObserver eventName="scratchIframeContentChanged" />
      </ThemeProvider>
    </StripeWrapper>
  );
}
