import React, { useState } from "react";
import { Box, Button, CircularProgress, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import FeedbackStarRating from "./FeedbackStarRating";
import { TFeedbackFormData } from "./types";

export default function FeedbackForm({
  data,
  setData,
  onSubmitHandler,
  formErrors
}: {
  data: TFeedbackFormData;
  setData: (data: TFeedbackFormData) => void;
  onSubmitHandler: () => void,
  formErrors: Record<string, any>
}) {

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    if (typeof onSubmitHandler === 'function') await onSubmitHandler();
    setIsLoading(false);
  };

  const setRating = (rating: number) => {
    setData({ ...data, rating });
  };
  const setMessage = (message: string) => {
    setData({ ...data, message: message || "" });
  };
  
  return (
    <Grid container gap={"75px"} justifyContent={"center"} direction={{ xs: "row" }} ml={"auto"} mr={"auto"}>
      <Grid item maxWidth={"350px"} pt={4}>
        <Box textAlign={"center"}>
          <img
            width={"206px"}
            src={
              "https://6598889.fs1.hubspotusercontent-na1.net/hubfs/6598889/Email templates/Vetcor/Reminder-Confirmation/art-feedback-after-visit-pets.png"
            }
            alt=""
          />
        </Box>
        <Typography variant={"h2"} mt={2} mb={4}>
          Tell us a little about your visit
        </Typography>
        <Typography fontSize={"22px"} lineHeight={"30px"} mb={0} mt={4}>
          Your feedback is crucial for maintaining the highest level of care for our patients.
        </Typography>
      </Grid>
      <Grid item maxWidth={"370px"} pt={4} mb={4}>
        <Box height={"80px"} sx={{ display: { xs: "none", md: "block" } }}></Box>
        <Typography fontSize={"16px"} mb={3} lineHeight={"20px"} fontWeight={700}>
          Please complete the survey below.
        </Typography>
        <Typography fontSize={"16px"} lineHeight={"20px"} mb={4}>
          How likely is it that you would recommend us to a friend or family member?
        </Typography>

        <FeedbackStarRating data={data} setRating={setRating} formErrors={formErrors} />

        <Typography fontSize={"16px"} mb={1}>
          Tell us why:
        </Typography>
        <TextField
          error={!!formErrors.message}
          value={data?.message}
          multiline
          placeholder="Tell us why"
          rows={5}
          fullWidth
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
          helperText={formErrors.message}
          sx={{
            '.MuiInputBase-input': { fontSize: '14px' },
          }}
        />
        <Box textAlign={"right"} mt={3}>
          <FormHelperText hidden={!formErrors.pageError} sx={{mb:2,mt:-2}} error>{formErrors.pageError}</FormHelperText>
          <Button onClick={onSubmit} size="large" variant="contained" sx={{ width: "145px", background: '#5B70FF' }}>
            <CircularProgress size={'20px'} sx={{color: 'white', mr: 1, display: isLoading ? 'inline-block':'none'}} />
            Send
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
