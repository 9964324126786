import { useState } from "react";
import * as FeedbackApi from "../api/feedback";
import { TFeedbackFormData } from "../types";

interface IUseFeedback {
  isSuccessful: boolean;
  formErrors: Record<string, string>;
  submitFeedbackHandler: (feedbackToken: string, data: TFeedbackFormData) => Promise<void>;
  feedbackResponse: Record<string, any>;
}

const dataToPayload = (data: TFeedbackFormData) => {
  return Object.assign(
    {
      nps_score: data.rating || 0,
    },
    data.message && { nps_reason: data.message || "" }
  );
};

const useFeedback = (): IUseFeedback => {
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [feedbackResponse, setFeedbackResponse] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<Record<string, any>>({});

  const submitFeedbackHandler = async (feedbackToken: string, data: TFeedbackFormData) => {
    // Validate
    if (data.rating <= 0) {
      return setFormErrors({
        rating: data.rating <= 0 ? "Required field" : "",
      });
    }
    try {
      const payload = dataToPayload(data);
      const res = await FeedbackApi.submitFeedback(feedbackToken, payload);
      setIsSuccessful(true);
      setFeedbackResponse(res.data);
    } catch (err: any) {
      setFormErrors({
        pageError: `Some problem submitting form. Please try again.`,
      });
    }
  };

  return { isSuccessful, formErrors, submitFeedbackHandler, feedbackResponse };
};

export default useFeedback;
