import React from "react";
import { TAppointmentReason, TDoctor, TPracticeDoctor, TAppointmentFormValues, TPracticeData } from "../types";
import { FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { selectMenuProps } from "../../../utils/select-menu-props.util";
import { FormikProps } from "formik";
import { NO_PREFERRED_DOCTOR } from "../hooks/useSmartDateAPI";

type TPreferredDoctorProps = {
  practiceData: TPracticeData;
  selectedAppointmentReason: TAppointmentReason | null;
} & Pick<
  FormikProps<TAppointmentFormValues>,
  "touched" | "errors" | "values" | "handleBlur" | "handleChange"
>;

const PreferredDoctor = ({
  practiceData,
  handleChange,
  handleBlur,
  touched,
  errors,
  selectedAppointmentReason,
  values
}: TPreferredDoctorProps) => {
  if (!practiceData) {
    return null;
  }

  if (practiceData.isSmartAppointmentRequestsEnabled) {
    let doctorsOptions: TDoctor[] = selectedAppointmentReason?.appointmentReasonDoctor?.map(apptReasonDoctor=>apptReasonDoctor.practiceDoctor)
      .filter((doctor: TPracticeDoctor) => doctor !== undefined && doctor.isShown && doctor.isActive) as TPracticeDoctor[];

    if (selectedAppointmentReason?.allDoctors) {
      doctorsOptions = practiceData.doctorList;
    }

    if(doctorsOptions?.length === 0){
      return null;
    }

    return (
      <Grid item xs={12} sm={values.isDirectBookingEnabled ? 12 : 6} mb={1}>
        <InputLabel shrink>{values.isDirectBookingEnabled ? 'Select' : 'Preferred'} doctor</InputLabel>
        <Select
          MenuProps={{
            ...selectMenuProps
          }}
          fullWidth
          name={"preferredDoctor"}
          value={values.preferredDoctor}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.preferredDoctor && Boolean(errors.preferredDoctor)}
        >
          <MenuItem value={NO_PREFERRED_DOCTOR}>{NO_PREFERRED_DOCTOR}</MenuItem>
          {doctorsOptions?.length > 0 &&
            doctorsOptions?.map((doctor: TDoctor) => (
              <MenuItem key={doctor.id} value={doctor.id}>
                {doctor.displayName}
              </MenuItem>
            ))}
        </Select>
        {touched.preferredDoctor && errors.preferredDoctor && (
          <FormHelperText error>{errors.preferredDoctor}</FormHelperText>
        )}
      </Grid>
    );
  }

  return practiceData?.doctorNames?.length > 0 ? (
    <Grid item xs={12} sm={6} mb={1}>
      <InputLabel shrink>Preferred doctor</InputLabel>
      <Select
        MenuProps={{
          ...selectMenuProps
        }}
        fullWidth
        name={"preferredDoctor"}
        value={values.preferredDoctor}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.preferredDoctor && Boolean(errors.preferredDoctor)}
      >
        {practiceData.doctorNames.map((name: string) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {touched.preferredDoctor && errors.preferredDoctor && (
        <FormHelperText error>{errors.preferredDoctor}</FormHelperText>
      )}
    </Grid>
  ) : null;
};

export default PreferredDoctor;
