import { useState } from "react";
import { confirmAppointment as confirmAppointmentRequest } from "../api/appointments";

interface IUseConfirmAppointment {
  isLoading: boolean;
  isSuccessful: boolean;
  error: boolean;
  confirmAppointment: (emailToken: string | undefined) => Promise<void>;
  errorMessage?: string;
}

const useConfirmAppointment = (): IUseConfirmAppointment => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const confirmAppointment = async (emailToken: string | undefined) => {
    if (!emailToken) {
      setError(true);
      return;
    }

    setLoading(true);
    try {
      await confirmAppointmentRequest(emailToken);
      setIsSuccessful(true);
    } catch (err: any) {
      if (err?.response?.data?.code == "E20000") {
        setErrorMessage(err?.response?.data?.message);
      }
      setError(true);
    }
    setLoading(false);
  };

  return { isSuccessful, isLoading, error, errorMessage, confirmAppointment };
};

export default useConfirmAppointment;
