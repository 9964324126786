import React, { ChangeEvent } from "react";
import { MenuItem, TextField, InputLabel } from "@mui/material";
import { IDynamicFormProps } from "./types";

const DynamicSelect: React.FC<IDynamicFormProps> = ({
  fieldId,
  fieldLabel,
  fieldMandatory,
  fieldValue,
  fieldHelperText,
  fieldOptions,
  fieldDisabled,
  handleChange,
}) => (
  <>
    <InputLabel shrink sx={{ whiteSpace: "pre-wrap" }}>
      {`${fieldLabel}${fieldMandatory ? "*" : ""}`}
    </InputLabel>
    <TextField
      select
      disabled={fieldDisabled}
      required={fieldMandatory}
      id={fieldId}
      helperText={fieldHelperText}
      onChange={(event) =>
        handleChange(fieldId, event as ChangeEvent<HTMLInputElement>)
      }
      value={fieldValue ?? ""}
      sx={{ minWidth: 210 }}
    >
      {fieldOptions &&
        fieldOptions.length > 0 &&
        fieldOptions.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
    </TextField>
  </>
);

export default DynamicSelect;
