import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const AlertIcon = createSvgIcon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <path
        className="st0"
        d="M50,61.7c-1.8,0-3.2,1.5-3.2,3.2c0,1.8,1.5,3.2,3.2,3.2s3.2-1.5,3.2-3.2C53.2,63.2,51.8,61.7,50,61.7z"
      />
      <path
        className="st0"
        d="M80.1,62L59.7,26.8c-2-3.5-5.7-5.7-9.7-5.7c-4.1,0-7.7,2.1-9.7,5.6L19.9,62c-2,3.5-2,7.8,0,11.3
		s5.7,5.6,9.7,5.6h40.7c4.1,0,7.7-2.1,9.8-5.7C82.1,69.7,82.1,65.5,80.1,62z M75.7,70.7c-1.1,1.9-3.1,3.1-5.4,3.1H29.6
		c-2.2,0-4.2-1.1-5.3-3c-1.1-1.9-1.1-4.3,0-6.2l20.4-35.3c1.1-1.9,3.1-3.1,5.3-3.1s4.2,1.2,5.3,3.1l20.4,35.3
		C76.8,66.5,76.8,68.8,75.7,70.7z"
      />
      <path
        className="st0"
        d="M49.2,38.9c-1.5,0.4-2.5,1.8-2.5,3.5c0.1,1,0.1,2.1,0.2,3.1c0.2,3.9,0.4,7.7,0.7,11.7c0.1,1.3,1.1,2.3,2.4,2.3
		c1.3,0,2.4-1,2.4-2.4c0-0.8,0-1.5,0.1-2.4c0.1-2.5,0.3-5,0.4-7.5c0.1-1.6,0.2-3.2,0.3-4.9c0-0.6-0.1-1.1-0.3-1.6
		C52.3,39.3,50.7,38.5,49.2,38.9z"
      />
    </g>
  </svg>,
  "AlertIcon"
);

export default AlertIcon;
