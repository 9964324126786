import React from "react";
import { Alert, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { TAppointmentFormValues, TPracticeData } from "../types";

type TSuccessStepProps = {
  practiceData: TPracticeData | undefined;
  values: TAppointmentFormValues;
};

const SuccessStep: React.FC<TSuccessStepProps> = ({ practiceData, values }) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} justifyContent="center" alignItems="center" pt={5}>
        <Alert
          severity="success"
          icon={false}
          sx={{ background: "#d2fcdd", border: "1px solid #70c989" }}
        >
          {!values.isDirectBookingEnabled && (
            <Typography fontSize={14}>
              Your appointment request has been sent
              {practiceData ? ` to ${practiceData.practiceName}` : ""}.
              <br />
              <br />
              {practiceData?.successCustomCopy}
            </Typography>
          )}
          {values.isDirectBookingEnabled && (
            <Typography fontSize={14}>
              {values.pets[0]?.name}&apos;s appointment has been booked with {practiceData?.practiceName} on {dayjs(values.preferredDate).format("MM/DD/YYYY")} at {values.preferredTime}
              <br />
              <br />
              {practiceData?.directBookingCustomCopy}
            </Typography>
          )}
        </Alert>
        <br />
        <br />
      </Grid>
    </Grid>
  );
};

export default SuccessStep;
